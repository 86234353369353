import { combineReducers } from 'redux';
import { reducer as oidcReducer } from 'redux-oidc';

import csoReducer from './csoReducer';
import appReducer from './appReducer';
import orderLinesReducer from './orderLinesReducer';
import childrenDictionaryReducer from './childrenDictionaryReducer';
import purchaseOrdersReducer from './purchaseOrdersReducer';

const reducer = combineReducers({
  oidc: oidcReducer,
  app: appReducer,
  orderLines: orderLinesReducer,
  cso: csoReducer,
  childrenDictionary: childrenDictionaryReducer,
  purchaseOrders: purchaseOrdersReducer,
});

export default reducer;

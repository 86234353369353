export const deliveryMethods = [
  {
    label: 'Dropshipping',
    value: 'direct-to-consumer',
    method: 'Delivered',
  },
  {
    label: 'Gadero Groningen',
    value: 'gadero-groningen',
    method: 'Stock',
    options: [
      {
        label: 'Bulk',
        value: 'gadero-groningen-stock',
        method: 'Stock',
        address: {
          street: 'Euvelgunnerweg',
          number: '19',
          numberAddition: '',
          zip: '9723 CT',
          place: 'Groningen',
          country: 'Nederland',
          countryCode: 'NL',
        },
      },
      {
        label: 'Via overslag',
        value: 'gadero-groningen-trans-shipment',
        method: 'TransShipment',
        address: {
          street: 'Euvelgunnerweg',
          number: '19',
          numberAddition: '',
          zip: '9723 CT',
          place: 'Groningen',
          country: 'Nederland',
          countryCode: 'NL',
        },
      },
      {
        label: '48 uurs',
        value: 'gadero-groningen-express',
        method: 'Express',
        address: {
          street: 'Euvelgunnerweg',
          number: '19',
          numberAddition: '',
          zip: '9723 CT',
          place: 'Groningen',
          country: 'Nederland',
          countryCode: 'NL',
        },
      },
      {
        label: 'Groningen - Afhalers',
        value: 'gadero-groningen-local-pickup',
        method: 'LocalPickup',
        address: {
          street: 'Euvelgunnerweg',
          number: '19',
          numberAddition: '',
          zip: '9723 CT',
          place: 'Groningen',
          country: 'Nederland',
          countryCode: 'NL',
        },
      },
      {
        label: 'Groningen - Koerier',
        value: 'gadero-groningen-courier',
        method: 'Courier',
        address: {
          street: 'Euvelgunnerweg',
          number: '19',
          numberAddition: '',
          zip: '9723 CT',
          place: 'Groningen',
          country: 'Nederland',
          countryCode: 'NL',
        },
      },
      {
        label: 'Groningen - Pakketpost',
        value: 'gadero-groningen-delivered',
        method: 'Delivered',
        address: {
          street: 'Euvelgunnerweg',
          number: '19',
          numberAddition: '',
          zip: '9723 CT',
          place: 'Groningen',
          country: 'Nederland',
          countryCode: 'NL',
        },
      },
    ],
    address: {
      street: 'Euvelgunnerweg',
      number: '19',
      numberAddition: '',
      zip: '9723 CT',
      place: 'Groningen',
      country: 'Nederland',
      countryCode: 'NL',
    },
  },
  {
    label: 'Breukelen',
    value: 'gadero-breukelen',
    options: [
      {
        label: 'Breukelen - Afhalers',
        value: 'gadero-breukelen-local-pickup',
        method: 'LocalPickup',
        address: {
          street: 'Straatweg',
          number: '5',
          numberAddition: '',
          zip: '3621 BG',
          place: 'Breukelen',
          country: 'Nederland',
          countryCode: 'NL',
        },
      },
      {
        label: 'Breukelen - Koerier',
        value: 'gadero-breukelen-courier',
        method: 'Courier',
        address: {
          street: 'Straatweg',
          number: '5',
          numberAddition: '',
          zip: '3621 BG',
          place: 'Breukelen',
          country: 'Nederland',
          countryCode: 'NL',
        },
      },
    ],
    address: {
      street: 'Straatweg',
      number: '5',
      numberAddition: '',
      zip: '3621 BG',
      place: 'Breukelen',
      country: 'Nederland',
      countryCode: 'NL',
    },
  },
  {
    label: 'Overige locaties',
    value: 'gadero-breukelen',
    options: [
      {
        label: 'Aalst - Afhalers',
        value: 'gadero-aalst-local-pickup',
        method: 'LocalPickup',
        address: {
          street: 'Hekkestraat',
          number: '20',
          numberAddition: '',
          zip: '9308',
          place: 'Hofstade-Aalst',
          country: 'België',
          countryCode: 'BE',
        },
      },
      {
        label: 'Frankrijk - Afhalers',
        value: 'gadero-frankrijk-local-pickup',
        method: 'LocalPickup',
        address: {
          street: 'Saint-Denis les Lucs',
          number: '85170',
          numberAddition: '',
          zip: ' ',
          place: 'Saint-Denis-la-Chevasse',
          country: 'Frankrijk',
          countryCode: 'FR',
        },
      },
      {
        label: 'Meppen - Afhalers',
        value: 'gadero-meppen-local-pickup',
        method: 'LocalPickup',
        address: {
          street: 'Lilienthalstraße',
          number: '11',
          numberAddition: '',
          zip: '49716',
          place: 'Meppen',
          country: 'Duitsland',
          countryCode: 'DE',
        },
      },
      {
        label: 'Waalwijk - Afhalers',
        value: 'gadero-waalwijk-local-pickup',
        method: 'LocalPickup',
        address: {
          street: 'Van Hilstraat',
          number: '25',
          numberAddition: '',
          zip: '5145 RK',
          place: 'Waalwijk',
          country: 'Nederland',
          countryCode: 'NL',
        },
      },
      {
        label: 'Winkel - Afhalers',
        value: 'gadero-winkel-local-pickup',
        method: 'LocalPickup',
        address: {
          street: 'Scheidersweg',
          number: '2',
          numberAddition: 'A',
          zip: '1731 LX',
          place: 'Winkel',
          country: 'Nederland',
          countryCode: 'NL',
        },
      },
    ],
  },
];

import styled from 'styled-components';

export const ProductCard = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.1);
`;

export const ProductCardHeader = styled.div`
  padding: 15px 20px;
  .select *:hover {
    cursor: pointer;
  }
`;

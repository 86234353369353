import React from 'react';
import {Col, Row} from 'react-bootstrap';

import {CustomerInformationCard} from './customer-information/customer-information-card';
import {Payment} from './payments/Payment';

export const OrderDetails = ({salesOrder}) => {
  return (
    <div className="order-details">
      <Row className="mt-4">
        <Col lg={{size: 4}}>
          <Payment salesOrder={salesOrder} />
        </Col>
        <Col lg={{size: 4}}>
          <CustomerInformationCard
            title="Invoice details"
            customerData={salesOrder.buyer}
            isBuyer={true}
            hasNetSuiteId={salesOrder.hasNetSuiteId}
          />
        </Col>
        <Col lg={{size: 4}}>
          <CustomerInformationCard
            title="Delivery details"
            customerData={salesOrder.receiver}
            isBuyer={false}
            hasNetSuiteId={salesOrder.hasNetSuiteId}
          />
        </Col>
      </Row>
      <hr />
    </div>
  );
};

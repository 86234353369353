import {v4 as uuidv4} from 'uuid';

import {transformPIMProductToGoodsItem} from '../../orderLines/transformPIMProductToGoodsItem';
import {updateGoodsItems} from '../../../apis/ApiCalls';
import {notifyError, notifyWarning} from '../../toasts';
import {
  rebuildSalesOrderGoodsItems,
  transformSalesOrderGoodsItemsToOrderLines,
} from '../../orderLines/transformSalesOrderGoodsItemsToOrderLines';
import {mergeOrderlines} from '../../orderLines/mergeOrderLines';
import store from '../../../redux/store';
import {buildChildrenDictionary} from '../../dictionary/buildChildrenDictionary';
import {deleteTypesFromGoodsItems} from '../../orderLines/deleteTypesFromGoodsItems';
import {refreshCSO} from '../refreshCSO';
import {goodsItemDecorator} from '../../orderLines/addPropsToGoodsItems';

/**
 * This function will either take a product from PIM or a custom made product and convert it to a goodsItem and add it to the CSO
 * @param {object} inventoryItem The product that will be added to the CSO
 * @param {number} amount The amount of products that will be added
 * @returns {Promise<void>}
 */
export async function addInventoryItemToSalesOrder(inventoryItem, amount) {
  const newGoodsItem = transformPIMProductToGoodsItem(inventoryItem, amount);

  const decoratedNewGoodsItem = goodsItemDecorator(newGoodsItem);

  const itemOrderline = transformSalesOrderGoodsItemsToOrderLines([
    decoratedNewGoodsItem,
  ]);
  const newOrderlines = mergeOrderlines(store.getState().orderLines, itemOrderline);
  const newChildrenDictionary = buildChildrenDictionary(Object.values(newOrderlines));

  const newOriginalGoodsItems = rebuildSalesOrderGoodsItems(
    newOrderlines,
    newChildrenDictionary
  );

  let postObject = {
    orderId: this.id,
    orderType: 'salesOrder',
    transactionId: uuidv4(),
    goodsItems: deleteTypesFromGoodsItems(newOriginalGoodsItems),
  };

  try {
    const {data} = await updateGoodsItems(postObject);

    if (data.success) {
      refreshCSO();
    } else {
      notifyWarning('Toevoegen is mislukt, neem contact op met IT');
    }
  } catch (error) {
    notifyError('Er is iets misgegaan, neem bij herhaling contact op met IT', error);
  }
}

import React from 'react';
import {Modal, Button, Table} from 'react-bootstrap';
import {format, parseISO} from 'date-fns';

import {StickyModalFooter} from './StickyModalFooter';

const OrderLogModal = ({handleClose, orderLog}) => {
  return (
    <>
      <Modal.Header>
        <Modal.Title>Order log</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table striped bordered>
          <thead>
            <tr>
              <th>User</th>
              <th>Event</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            {orderLog.map((item) => {
              return (
                <tr key={item.eventDateTime}>
                  <td>{item.user}</td>
                  <td>{item.eventName}</td>
                  <td>{format(parseISO(item.eventDateTime), 'dd-MM-yyyy')}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Modal.Body>
      <StickyModalFooter>
        <Button variant="danger" onClick={handleClose}>
          Close
        </Button>
      </StickyModalFooter>
    </>
  );
};

export default OrderLogModal;

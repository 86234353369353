import React from 'react';
import {Table} from 'react-bootstrap';

const MainInformationTable = ({
  productDetails,
  handleLocationSelect,
  selectedLocation,
}) => {
  return (
    <Table className="mt-4">
      <thead>
        <tr>
          <th>Location</th>
          <th>Reservations</th>
          <th>Purchase orders</th>
          <th>Stock</th>
          <th>Available stock</th>
        </tr>
      </thead>
      <tbody>
        {productDetails.stock.map((stockLocation) => {
          return (
            <tr
              key={stockLocation.location}
              onClick={() => handleLocationSelect(stockLocation.location)}
              style={{
                backgroundColor:
                  stockLocation.location === selectedLocation ? '#f7f7f7' : '',
                cursor: 'pointer',
              }}
            >
              <td>{stockLocation.location}</td>
              <td>
                {stockLocation.reservations.reduce(
                  (previousValue, currentValue) => previousValue + currentValue.count,
                  0
                )}
              </td>
              <td>
                {stockLocation.purchaseOrders.reduce(
                  (previousValue, currentValue) => previousValue + currentValue.count,
                  0
                )}
              </td>
              <td>{stockLocation.stock}</td>
              <td>{stockLocation.availableStock}</td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

export default MainInformationTable;

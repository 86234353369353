import React, {useEffect, useState} from 'react';
import {Button, Col, Row} from 'react-bootstrap';
import {FaPen, FaTrash} from 'react-icons/fa';

import {
  ProductGroupWrapper,
  ProductWrapper,
} from '../../../shared/product/product-row-components';
import {ProductTitle} from '../../../shared/product/ProductTitle';
import {BaseModal} from '../../../shared/modals/BaseModal';
import {TotalSellPrice} from '../../../shared/product/TotalSellPrice';
import {TotalCostPrice} from '../../../shared/product/TotalCostPrice';
import {TotalPriceDifference} from '../../../shared/product/TotalPriceDifference';

import AssignableProductRow from './AssignableProductRow';
import csoManager from '../../../../utils/csoManager/csoManager';
import {KitItemRow} from './KitItemRow';
import {productTypes} from '../../../../utils/determineItemTypeOnPimProduct';

const ProductOptionRow = ({item, childrenDictionary, orderLinesObject}) => {
  const [showModal, setShowModal] = useState({isVisible: false, type: ''});
  const childIds = childrenDictionary[item.orderlineId];
  let costPriceSumChildren = 0;

  useEffect(() => {
    return () => setShowModal({isVisible: false, type: ''});
  }, []);

  if (childIds) {
    costPriceSumChildren = childIds.reduce(
      (a, b) => a + orderLinesObject[b].selectedSupplier?.costPrice.value,
      0
    );
  }

  return (
    <>
      {item.item.subItems.length > 0 && (
        <ProductGroupWrapper level={1}>
          <ProductWrapper level={1}>
            <Row>
              <Col>
                <ProductTitle
                  id={item.item.id}
                  externalIds={item.item.externalIds}
                  option
                >
                  {item.item.name}
                </ProductTitle>

                <Row>
                  <Col>{''}</Col>
                  <Col md={4}>{''}</Col>
                </Row>
              </Col>
              <Col md={3}>
                <TotalSellPrice amount={item.quantity.value} item={item} />

                <TotalCostPrice item={item} sumOfChildren={costPriceSumChildren} />

                <TotalPriceDifference
                  item={item}
                  costPriceOfChildren={costPriceSumChildren}
                />

                <div className="mt-2 d-flex justify-content-end">
                  <>
                    <Button
                      disabled={csoManager?.current?.hasNetSuiteId}
                      className="ml-2 d-flex align-items-center"
                      size="sm"
                      variant="outline-danger"
                      onClick={() => setShowModal({isVisible: true, type: 'delete'})}
                    >
                      <FaTrash title="Remove product" size={16} />
                    </Button>
                    <Button
                      disabled={csoManager?.current?.hasNetSuiteId}
                      className="ml-2 d-flex align-items-center"
                      size="sm"
                      variant="outline-secondary"
                      onClick={() => setShowModal({isVisible: true, type: 'update'})}
                    >
                      <FaPen title="Edit price" size={16} />
                    </Button>
                  </>
                </div>
              </Col>
            </Row>
          </ProductWrapper>

          {childrenDictionary[item.orderlineId]
            .filter((el) => !orderLinesObject[el].fullyAssigned)
            .map((key) => {
              if (orderLinesObject[key].item.itemType === productTypes.kitItem) {
                return (
                  <KitItemRow
                    level={2}
                    key={key}
                    item={orderLinesObject[key]}
                    orderLinesObject={orderLinesObject}
                    childrenDictionary={childrenDictionary}
                  />
                );
              } else {
                return (
                  <AssignableProductRow
                    key={key}
                    level={2}
                    item={orderLinesObject[key]}
                  />
                );
              }
            })}
        </ProductGroupWrapper>
      )}

      {item.item.subItems.length === 0 && (
        <ProductWrapper level={1}>
          <Row>
            <Col>
              <ProductTitle
                externalIds={item.item.externalIds}
                productType={item}
                id={item.item.id}
                option
              >
                {item.item.name}
              </ProductTitle>
            </Col>
            <Col md={2} className="d-flex justify-content-end align-items-center">
              <Button
                size="sm"
                onClick={() => setShowModal({isVisible: true, type: 'find'})}
              >
                Zoek
              </Button>
            </Col>
          </Row>
        </ProductWrapper>
      )}

      <BaseModal
        isVisible={showModal.isVisible}
        type={showModal.type}
        onClose={() => setShowModal({isVisible: false, type: ''})}
        option={true}
        selectedProduct={item}
      />
    </>
  );
};

export default ProductOptionRow;

import React from 'react';
import {Modal, Button} from 'react-bootstrap';

import ProductFinder from './ProductFinder';
import {StickyModalFooter} from './StickyModalFooter';

const ProductFinderModal = ({handleClose, option, item}) => {
  const createTitle = () => {
    if (option) {
      return 'Add product option';
    } else {
      return 'Add product';
    }
  };

  return (
    <>
      <Modal.Header>
        <Modal.Title>{createTitle()}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ProductFinder option={option} selectedItem={item} />
      </Modal.Body>
      <StickyModalFooter>
        <Button variant="danger" onClick={handleClose}>
          Close
        </Button>
      </StickyModalFooter>
    </>
  );
};

export default ProductFinderModal;

import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { FaSearch } from 'react-icons/fa';
import styled from 'styled-components';
import { FormControl } from 'react-bootstrap';
import debounce from 'lodash.debounce';

const Search = styled.div`
  border: 1px solid #ced4da;
  display: flex;
  align-items: center;
  padding-right: 10px;
  color: #495057;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  .form-control {
    border: none;

    &:focus {
      outline: 0;
      box-shadow: none;
    }
  }

  &:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
  }
`;

const SearchBar = ({ onChange, value, placeholder, delay = 300, ...rest }) => {
  const inputRef = useRef(null);
  const [inputValue, setInputValue] = useState(value);

  const debouncedSearch = debounce((newVal) => onChange(newVal), delay);

  const handleChange = () => {
    debouncedSearch(inputRef.current.value);
  };

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  return (
    <Search>
      <FormControl
        onChange={(event) => handleChange(event.target.value)}
        placeholder={placeholder}
        defaultValue={inputValue}
        ref={inputRef}
        {...rest}
      />

      <FaSearch />
    </Search>
  );
};

SearchBar.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  delay: PropTypes.number,
};

export default SearchBar;

import {deepCopy} from '../../deepCopy';
import {v4 as uuidv4} from 'uuid';
import {getSubItemsFromPIM} from '../../orderLines/getSubItemsFromPIM';
import {
  rebuildSalesOrderGoodsItems,
  transformSalesOrderGoodsItemsToOrderLines,
} from '../../orderLines/transformSalesOrderGoodsItemsToOrderLines';
import {mergeOrderlines} from '../../orderLines/mergeOrderLines';
import store from '../../../redux/store';
import {buildChildrenDictionary} from '../../dictionary/buildChildrenDictionary';
import {deleteTypesFromGoodsItems} from '../../orderLines/deleteTypesFromGoodsItems';
import {updateGoodsItems} from '../../../apis/ApiCalls';
import {notifyError, notifyWarning} from '../../toasts';
import {refreshCSO} from '../refreshCSO';

/**
 * This function will take a productoption from PIM and add it to the CSO
 * @param {object} parent The parent product of which the productoption is a child
 * @param {object} product The product that will be added to the CSO
 * @returns {Promise<void>}
 */
export async function addProductOptionToSalesOrder(parent, product) {
  // clone the parent to prevent state updates
  const clonedParent = deepCopy(parent);

  clonedParent.item.subItems = await getSubItemsFromPIM(product.subProducts, parent);

  const itemOrderline = transformSalesOrderGoodsItemsToOrderLines([clonedParent]);

  const newOrderlines = mergeOrderlines(store.getState().orderLines, itemOrderline);

  const newChildrenDictionary = buildChildrenDictionary(Object.values(newOrderlines));

  const newOriginalGoodsItems = rebuildSalesOrderGoodsItems(
    newOrderlines,
    newChildrenDictionary
  );

  const order = {
    orderId: this.id,
    transactionId: uuidv4(),
    orderType: 'salesOrder',
    goodsItems: deleteTypesFromGoodsItems(newOriginalGoodsItems),
  };

  try {
    const {data} = await updateGoodsItems(order);

    if (data.success) {
      refreshCSO();
    } else {
      notifyWarning('Toevoegen is mislukt, neem contact op met IT');
    }
  } catch (error) {
    notifyError('Er is iets misgegaan, neem contact op met IT', error);
  }
}

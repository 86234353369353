import {deliveryMethods} from './deliveryMethods';
import {deliveryOptions} from './deliveryOptions';

/**
 * Builds the delivery postObject that will be sent to the setDelivery API
 * @param {object} selectedDelivery The selected delivery
 * @param {object} purchaseOrder The purchaseOrder for which the delivery will be set
 * @param {object} CSO The CSO
 * @returns {{orderType, shipping: {deliveryAddress: {}, shippingMethod: string}, orderId}}
 */
export const generateDeliveryPostObject = ({selectedDelivery, purchaseOrder, CSO}) => {
  const postObject = {
    orderId: purchaseOrder.id,
    orderType: purchaseOrder.orderType,
    shipping: {
      deliveryAddress: {},
      shippingMethod: '',
    },
  };

  if (selectedDelivery.address) {
    postObject.shipping.deliveryAddress = selectedDelivery.address;
  } else if (selectedDelivery.value === 'direct-to-consumer') {
    postObject.shipping.deliveryAddress = CSO.receiver.address;
  }

  postObject.shipping.shippingMethod = selectedDelivery.method;

  delete postObject.shipping.$type;

  return postObject;
};

//todo cleanup after testing
/**
 * Return an shipping object based on a giving shippingMethod and address place
 * @param {string} currentMethod
 * @param {string} street
 * @returns
 */
export const getCurrentShipping = (currentMethod, street) => {
  return deliveryMethods.reduce((result, method) => {
    if (method.options) {
      const newFound = method.options.filter(
        (optionMethod) => optionMethod.method === currentMethod
      );

      if (newFound[0] && newFound[0].address.street === street) {
        result = newFound[0];
      }
    } else if (method.address?.street === street) {
      result = method;
    } else if (currentMethod === method.method) {
      result = method;
    }

    return result;
  }, {});
};

/**
 * Return an shipping object based on a giving shippingMethod and address place
 * @param {string} currentMethod
 * @param {string} street
 * @returns
 */
export const getCSOShippingMethod = (currentMethod, street) => {
  if (currentMethod === 'Delivered' && street !== 'Euvelgunnerweg') {
    return deliveryOptions[0];
  } else if (['LocalPickup', 'Courier'].includes(currentMethod)) {
    return deliveryOptions.filter(
      (option) => option.method === currentMethod && option.address.street === street
    )[0];
  } else {
    return deliveryOptions.filter((option) => option.method === currentMethod)[0];
  }
};

/**
 * Returns the correct delivery from the given purchaseOrder
 * @param {*} purchaseOrder A purchaseOrder object with shipping and deliveryAddress
 * @returns
 */
export const getCurrentDeliveryFromPO = (purchaseOrder) => {
  const currentMethod = purchaseOrder.shipping.shippingMethod;
  const currentStreet = purchaseOrder.shipping.deliveryAddress?.street;
  const found = getCSOShippingMethod(currentMethod, currentStreet);

  let shipping;

  if (Object.keys(found).length > 0) {
    shipping = found;
  } else if (currentMethod === 'Delivered') {
    shipping = deliveryMethods[0]; // Dropshipping on customers address
    shipping.address = purchaseOrder.shipping.deliveryAddress;
  } else {
    shipping = {
      label: 'Via overslag',
      value: 'gadero-groningen-trans-shipment',
      method: 'TransShipment',
      address: {
        street: 'Euvelgunnerweg',
        number: '19',
        numberAddition: '',
        zip: '9723 CT',
        place: 'Groningen',
        country: 'Nederland',
        countryCode: 'NL',
      },
    };
  }

  return shipping;
};

import React, {useState, memo} from 'react';
import {Link} from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import {Table, Row, Button} from 'react-bootstrap';
import qs from 'qs';

import {ticksToDateString} from '../../../utils/DateTimeUtils';

import SortableTh from './SortableTh';

const itemsPerPage = 250;

const OverviewTable = ({
  totalItems,
  orderList,
  onPaginate,
  onSort,
  currentPage,
  sortBy: sort,
  reverseSort: reverse,
}) => {
  const [sortBy, setSortBy] = useState(sort);
  const [reverseSort, setReverseSort] = useState(
    typeof reverse === 'string' ? reverse === 'true' : reverse
  );

  const pageCount = Math.ceil(totalItems / itemsPerPage);

  const handleSorting = (columnName) => {
    let sorting = {sort: columnName, reverse: setReverseSort, currentPage};

    if (sortBy !== columnName) {
      setSortBy(columnName);
      setReverseSort(true);

      sorting.sort = columnName;
      sorting.reverse = true;
    } else {
      setReverseSort(!reverseSort);
      sorting.reverse = !reverseSort;
    }

    onSort(sorting);
  };

  const handlePagination = ({selected: selectedPage}) => {
    onPaginate(selectedPage + 1);
  };

  const getQuery = () => {
    return qs.parse(window.location.search, {ignoreQueryPrefix: true});
  };

  return (
    <>
      <Table striped size="sm">
        <thead>
          <tr>
            <th>Order-id</th>
            <th>Status</th>
            <SortableTh
              label="Deliverydate"
              columnName="deadline"
              currentReverseSort={reverseSort}
              currentSortBy={sortBy}
              onClick={handleSorting}
            />
            <SortableTh
              label="Orderdate"
              columnName="orderCreated"
              currentReverseSort={reverseSort}
              currentSortBy={sortBy}
              onClick={handleSorting}
            />
            <th>Shipping method</th>
            <SortableTh
              label="Order amount"
              columnName="orderAmount"
              currentReverseSort={reverseSort}
              currentSortBy={sortBy}
              onClick={handleSorting}
            />
            <SortableTh
              label="Payment method"
              columnName="paymentMethod"
              currentReverseSort={reverseSort}
              currentSortBy={sortBy}
              onClick={handleSorting}
            />
            <th />
          </tr>
        </thead>
        <tbody>
          {orderList?.map(function (order) {
            return (
              <tr key={order.orderId}>
                <td>{order.buyerReference}</td>
                <td>{order.status}</td>
                <td>{ticksToDateString(order.deadline)}</td>
                <td>{ticksToDateString(order.orderCreated)}</td>
                <td>{order.shippingMethod}</td>
                <td>{order.orderAmount.toFixed(2)}</td>
                <td>{order.paymentMethod}</td>
                <td>
                  <Link
                    props={order}
                    to={{
                      pathname: `edit/client-sales-order/${order.orderId}`,
                      query: getQuery(),
                    }}
                  >
                    <Button size="sm">Open</Button>
                  </Link>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>

      <Row className="pagination-container">
        <ReactPaginate
          previousLabel={'← Previous'}
          nextLabel={'Next →'}
          onPageChange={handlePagination}
          forcePage={currentPage - 1}
          pageCount={pageCount}
          containerClassName={'pagination'}
          previousLinkClassName={'pagination-link'}
          nextLinkClassName={'pagination-link'}
          disabledClassName={'pagination-link-disabled'}
          activeClassName={'pagination-link-active'}
        />
      </Row>
    </>
  );
};

export default memo(OverviewTable);

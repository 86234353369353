export const countryCodeDictionary = {
  NL: 'Netherlands',
  DE: 'Germany',
  BE: 'Belgium',
  FR: 'France',
  UK: 'United Kingdom',
  ES: 'Spain',
  BG: 'Bulgaria',
  DK: 'Denmark',
  EE: 'Estonia',
  FI: 'Finland',
  GR: 'Greece',
  HU: 'Hungary',
  IT: 'Italy',
  KR: 'Croatia',
  LV: 'Latvia',
  LT: 'Lithuania',
  LU: 'Luxembourg',
  AT: 'Austria',
  PL: 'Poland',
  PT: 'Portugal',
  RO: 'Romania',
  SK: 'Slovakia',
  CZ: 'Czech Republic',
  SE: 'Sweden',
};

export const countryOptions = Object.entries(countryCodeDictionary).map(
  ([key, value]) => ({
    label: value,
    value: key,
  })
);

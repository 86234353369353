import React from 'react';

import Reservations from './Reservations';
import ExpectedPurchaseOrders from './ExpectedPurchaseOrders';

const StockLocation = ({ stockLocation }) => {
  return (
    <div>
      {stockLocation.reservations?.length > 0 && (
        <Reservations
          stockLocation={stockLocation}
          reservations={stockLocation?.reservations}
        />
      )}
      {stockLocation.purchaseOrders?.length > 0 && (
        <ExpectedPurchaseOrders
          purchaseOrders={stockLocation?.purchaseOrders}
          stockLocation={stockLocation}
        />
      )}
    </div>
  );
};

export default StockLocation;

import axios from 'axios';

const axiosHeaderMiddleWare = (store) => (next) => (action) => {
  let token;

  if (action.type === 'redux-oidc/USER_FOUND') {
    token = action.payload.access_token;
  } else {
    token = store.getState().oidc.user?.access_token || null;
  }

  axios.defaults.headers.common['Authorization'] = token ? 'Bearer ' + token : null;
  next(action);
};

export default axiosHeaderMiddleWare;

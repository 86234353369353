import React from 'react';
import {Row, Col} from 'react-bootstrap';
import {FaEye} from 'react-icons/fa';
import {NavLink} from 'react-router-dom';

import {LinkButton} from '../../../shared/buttons/LinkButton';
import {ProductWrapper} from '../../../shared/product/product-row-components';

const AssignedProductRow = ({item, purchaseOrders}) => {
  const findMatchingPO = (order) => {
    return item.assignedPurchases.find(
      (purchase) => purchase.purchaseOrderId === order.id
    );
  };

  const getSupplierName = () => {
    const matchingPO = purchaseOrders.filter(
      (order) => order.id === findMatchingPO(order)?.purchaseOrderId
    );

    return matchingPO[0]?.seller?.contact?.name;
  };

  return (
    <ProductWrapper>
      <Row>
        <Col>
          <span className="mb-0 h6 mr-2">{item.item?.name}</span>
          <LinkButton
            href={`${process.env.REACT_APP_tafkap_url}products/${item.item.id}/edit`}
          >
            <FaEye />
          </LinkButton>
          <NavLink
            className="ml-2"
            to={`/productdetails/${item.item.id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaEye color="red" />
          </NavLink>
        </Col>
        <Col md={4}>
          {['isOptionChild', 'isCombiChild'].includes(item.goodType) ? (
            <p className="m-0 h6 text-right">No selling price</p>
          ) : (
            <p className="m-0 h6 text-right">
              Selling price {item.price?.currency}{' '}
              {(+item.price?.value * +item.orderedQuantity.value).toFixed(2)}
            </p>
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          <p className="mb-0 text-muted">
            {item.orderedQuantity.value}{' '}
            {item.quantity?.unit ? item.quantity?.unit : 'units'}
            {item.selectedSupplier
              ? ' - ' + item.selectedSupplier?.name
              : getSupplierName()
              ? ' - ' + getSupplierName()
              : ''}
          </p>
        </Col>
        <Col md={4}></Col>
      </Row>
    </ProductWrapper>
  );
};

export default AssignedProductRow;

import {format} from 'date-fns';
import React from 'react';
import styled from 'styled-components';

const TimelineItem = styled.li`
  position: relative;
  margin-bottom: 1.5rem;

  &::after {
    position: absolute;
    display: block;
    background-color: hsl(0, 0%, 90%);
    left: -45.5px;
    top: 7px;
    border-radius: 50%;
    height: 11px;
    width: 11px;
    content: '';
  }
`;

const TimeLineParagraph = styled.p`
  color: hsl(0, 0%, 60%);
  font-size: 15px;
`;

const MutedSpan = styled.span`
  font-weight: bold;
  font-size: 16px;
  color: hsl(0, 0%, 30%);
  margin-right: 1vw;
`;

export const TimelineContainer = styled.ul`
  border-left: 1px solid #d3d3d3;
  position: relative;
  list-style: none;
`;

export const TimeLineEntry = ({title, date}) => (
  <TimelineItem>
    <MutedSpan>{date ? format(new Date(date), 'dd-MM-yyyy HH:mm') : 'no data'}</MutedSpan>
    <TimeLineParagraph>{title}</TimeLineParagraph>
  </TimelineItem>
);

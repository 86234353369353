export const IDENTITY_CONFIG = {
    authority: process.env.REACT_APP_AUTH_URL, //(string): The URL of the OIDC provider.
    client_id: process.env.REACT_APP_IDENTITY_CLIENT_ID, //(string): Your client application's identifier as registered with the OIDC provider.
    redirect_uri: process.env.REACT_APP_REDIRECT_URL, //The URI of your client application to receive a response from the OIDC provider.
    login: process.env.REACT_APP_AUTH_URL + '/login',
    automaticSilentRenew: true, //(boolean, default: false): Flag to indicate if there should be an automatic attempt to renew the access token prior to its expiration.
    loadUserInfo: true, //(boolean, default: true): Flag to control if additional identity data is loaded from the user info endpoint in order to populate the user's profile.
    silent_redirect_uri: process.env.REACT_APP_SILENT_REDIRECT_URL, //(string): The URL for the page containing the code handling the silent renew.
    post_logout_redirect_uri: process.env.REACT_APP_LOGOFF_REDIRECT_URL, // (string): The OIDC post-logout redirect URI.
    response_type: 'id_token token', // token z(string, default: 'id_token'): The type of response desired from the OIDC provider.
    grant_type: 'implicit',
    scope: 'openid roles order-processing-app', // (string, default: 'openid'): The scope being requested from the OIDC provider.
    webAuthResponseType: 'id_token token',
    monitorSession: true,
    //userStore: new WebStorageStateStore({ store: window.localStorage }), (is dit wel wijs?)
    revokeAccessTokenOnSignout: true,
};

import { set } from 'date-fns';

import { postDeadline } from '../../../apis/ApiCalls';
import { notifyError, notifySuccess } from '../../toasts';
import store from '../../../redux/store';
import { setDeadlineInState } from '../../../redux/actions/actions';
import CSOManager from '../csoManager';

/**
 * Sets the ultimate delivery date to customer
 * @param {date} date - Date for delivery
 * @returns {Promise<void>}
 */
export async function setDeadline(date) {
  const oldDate = store.getState().cso.deadLine;

  function isDateBeforeToday(date) {
    return new Date(date.toDateString()) < new Date(new Date().toDateString());
  }

  if (date && isDateBeforeToday(date)) {
    notifyError('Datum mag niet in het verleden liggen');

    return;
  }

  const postObject = {
    orderId: this.id,
    orderType: 'SalesOrder',
    deadLine: date ? set(new Date(date), { hours: 12 }) : date
  };

  try {
    const res = await postDeadline(postObject);

    if (res.data.success) {
      notifySuccess('Deadline bijgewerkt');
      store.dispatch(setDeadlineInState(date));

      const openPurchaseOrders = store.getState().purchaseOrders.filter(purchaseOrder => !purchaseOrder.isSentToSupplier);

      if (openPurchaseOrders.length > 0) {
        openPurchaseOrders.forEach(purchaseOrder => CSOManager.setPurchaseOrderDeadline(purchaseOrder.id, date));
      }

      const sentPurchaseOrdersWithSameDeadlineAsCSO = store.getState().purchaseOrders.filter(purchaseOrder => purchaseOrder.expectedDeliveryDate === oldDate);

      if (sentPurchaseOrdersWithSameDeadlineAsCSO.length > 0) {
        sentPurchaseOrdersWithSameDeadlineAsCSO.forEach(purchaseOrder => CSOManager.setPurchaseOrderDeadline(purchaseOrder.id, date));
      }

    }
  } catch (error) {
    notifyError('Er ging iets mis');
  }
}

import styled from 'styled-components';

export interface ClusterProps {
  align?: 'stretch' | 'center' | 'flex-start' | 'flex-end' | 'baseline' | 'initial' | 'inherit';
  justify?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
    | 'initial'
    | 'inherit';
  gap?: string;
}

export const Cluster = styled.div<ClusterProps>`
  display: flex;
  flex-wrap: wrap;
  gap: ${props => props.gap || 'var(--s1)'};
  justify-content: ${props => props.justify};
  align-items: ${props => props.align};
`;

export default Cluster;

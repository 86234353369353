import {
  getProductDetailsFromPIM,
  updateGoodsItems,
} from '../../../apis/ApiCalls';

import {v4 as uuidv4} from 'uuid';
import {transformPIMProductToGoodsItem} from '../../orderLines/transformPIMProductToGoodsItem';
import {goodsItemDecorator} from '../../orderLines/addPropsToGoodsItems';
import {
  rebuildSalesOrderGoodsItems,
  transformSalesOrderGoodsItemsToOrderLines,
} from '../../orderLines/transformSalesOrderGoodsItemsToOrderLines';
import {mergeOrderlines} from '../../orderLines/mergeOrderLines';
import store from '../../../redux/store';
import {buildChildrenDictionary} from '../../dictionary/buildChildrenDictionary';
import {deleteTypesFromGoodsItems} from '../../orderLines/deleteTypesFromGoodsItems';
import {notifyError} from '../../toasts';
import {productTypes} from '../../determineItemTypeOnPimProduct';
import {refreshCSO} from '../refreshCSO';

/**
 * Transforms a normal product on the CSO into a combination product by selecting
 * products from PIM and adding them as children to the original product
 * @param {object} parent Original product that will be converted to combi-product
 * @param {array} children Array of products from PIM that will become the children
 * of the original product
 * @returns {Promise<void>}
 */
export async function transformInventoryItemWithoutChildrenIntoKitItem(parent, children) {
  parent.item.itemType = productTypes.kitItem;
  parent.checked = false;

  let newSubItems = [];

  for (const child of children) {
    const productInfo = await getProductDetailsFromPIM(child.productId);

    const transformedSubItem = transformPIMProductToGoodsItem(
      productInfo.data,
      child.quantity.value,
      false
    );

    const newUpdatedSubItem = goodsItemDecorator(transformedSubItem, parent);
    newSubItems.push(newUpdatedSubItem);
  }

  parent.item.subItems = newSubItems;

  const itemOrderLine = transformSalesOrderGoodsItemsToOrderLines([parent]);
  const newOrderLines = mergeOrderlines(store.getState().orderLines, itemOrderLine);
  const newChildrenDictionary = buildChildrenDictionary(Object.values(newOrderLines));

  const newOriginalGoodsItems = rebuildSalesOrderGoodsItems(
    newOrderLines,
    newChildrenDictionary
  );

  let postObject = {
    orderId: this.id,
    orderType: 'salesOrder',
    transactionId: uuidv4(),
    goodsItems: deleteTypesFromGoodsItems(newOriginalGoodsItems),
  };

  try {
    const {data} = await updateGoodsItems(postObject);

    if (data.success) {
      refreshCSO();
    }
  } catch (error) {
    notifyError('Iets ging mis bij het maken van het combinatieproduct', error);
  }
}

/**
 * Helper function that makes a deep copy of objects and array, it is used to
 * prevent unwanted state updates by making a copy of state. This version also
 * supports copying dates
 * @param objectToBeCloned
 * @returns {*[]|*}
 */
export const deepCopy = (objectToBeCloned) => {
  let resultObj, value, key;

  if (typeof objectToBeCloned !== 'object' || objectToBeCloned === null) {
    return objectToBeCloned;
  }

  if (typeof objectToBeCloned === 'object') {
    if (objectToBeCloned.constructor.name === 'Date') {
      resultObj = new objectToBeCloned.constructor(objectToBeCloned);
    } else {
      resultObj = Array.isArray(objectToBeCloned) ? [] : {};
    }
  }

  for (key in objectToBeCloned) {
    value = objectToBeCloned[key];

    // Recursively copy for nested objects & arrays
    resultObj[key] = deepCopy(value);
  }

  return resultObj;
};

import React, {useState} from 'react';
import {Card, Col, Row, Button} from 'react-bootstrap';
import {useSelector} from 'react-redux';

import {BaseModal} from '../modals/BaseModal';
import CSOManager from '../../../utils/csoManager/csoManager';
import {Stack} from '../layout/stack/stack';

import {ProductCard} from './ProductCard';
import {ProductCardTitle} from './ProductCardTitle';
import {ProductRowType} from './ProductRowType';

export const ProductContainer = ({orderLinesObject, childrenDictionary, salesOrder}) => {
  const [showModal, setShowModal] = useState({isVisible: false, type: ''});
  const purchaseOrders = useSelector((state) => state.purchaseOrders);

  const unassignedAmount =
    Object.values(orderLinesObject).filter((item) => !item.fullyAssigned).length || 0;

  const areAllPoShipped = purchaseOrders.every((i) => i.orderIsShipped);

  return (
    <>
      <ProductCard>
        <Row className="pt-3 px-3">
          <Col>
            <ProductCardTitle value="Not assigned products" amount={unassignedAmount} />
            {salesOrder.status === 'Cancelled' ? null : (
              <span style={{float: 'right'}}>
                {(unassignedAmount === 0 &&
                  salesOrder.status === 'FullyAssigned' &&
                  areAllPoShipped) ||
                CSOManager?.current?.hasNetSuiteId ? (
                  <Button disabled variant="outline-secondary">
                    Unable to add products
                  </Button>
                ) : (
                  <Button
                    variant="outline-secondary"
                    onClick={() => setShowModal({isVisible: true, type: 'find'})}
                    size="sm"
                  >
                    Add product
                  </Button>
                )}
              </span>
            )}
          </Col>
        </Row>
        <hr />
        <Card.Body>
          <Stack>
            {Object.values(orderLinesObject)
              .filter((el) => !el.fullyAssigned)
              .map((item, index) => (
                <ProductRowType
                  key={index}
                  item={item}
                  childrenDictionary={childrenDictionary}
                  orderLinesObject={orderLinesObject}
                />
              ))}
          </Stack>
        </Card.Body>
      </ProductCard>

      <BaseModal
        isVisible={showModal?.isVisible}
        type={showModal.type}
        selectedProduct={null}
        option={false}
        onClose={() => setShowModal({isVisible: false, type: null})}
      />
    </>
  );
};

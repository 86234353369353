import styled from 'styled-components';
import {productTypes} from '../../../utils/determineItemTypeOnPimProduct';

export const OrderWrapper = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.1);
  height: 100%;
  /* overflow-y: auto; */
  padding: 20px;
`;

export const ProductTooltipBox = styled.div`
  position: absolute;
  top: -60px;
  left: 20%;
  visibility: hidden;
  color: transparent;
  background-color: transparent;
  width: 150px;
  padding: 5px 5px;
  border-radius: 4px;

  &:before {
    content: '';
    width: 0;
    height: 0;
    left: 40px;
    bottom: -10px;
    position: absolute;
    border: 10px solid transparent;
    transform: rotate(315deg);
  }
`;

export const ProductWrapper = styled.div`
  border: ${(props) =>
    props.emptyGuid
      ? '2px solid rgba(255, 0, 0, 0.75)'
      : '1px solid rgba(0, 0, 0, 0.25)'};
  padding: 0.5rem;
  padding-inline-start: ${(props) => (props.level ? `${props.level * 2}rem` : '.5')};
  background-color: ${(props) =>
    props.itemType === productTypes.inventoryItem ? 'inherit' : `rgba(0, 0, 0, ${0.1})`};
`;

export const ToolTipWrapper = styled.div`
  position: relative;

  & ${ProductWrapper}:hover + ${ProductTooltipBox} {
    visibility: visible;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.8);
    width: 230px;
    padding: 8px 8px;

    &:before {
      border-color: transparent transparent rgba(0, 0, 0, 0.8) rgba(0, 0, 0, 0.8);
    }
  }
`;

export const ProductGroupWrapper = styled.div`
  border: ${(props) => (!props.level ? '1px solid rgba(0, 0, 0, 0.25)' : 'none')};
`;

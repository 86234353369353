import {v4 as uuidv4} from 'uuid';

import {productTypes} from '../determineItemTypeOnPimProduct';

const setAllowedActions = (item, hasParent) => {
  const baseActions = {
    isAssignable: false,
    isMutable: false,
    hasOwnPrice: false,
    isRemovable: false,
    canBecomeKitItem: false,
  };

  // InventoryItem that has no relations
  if (
    item.item.itemType === productTypes.inventoryItem &&
    !hasParent &&
    item.item.subItems.length === 0
  ) {
    return {
      isAssignable: true,
      isMutable: true,
      hasOwnPrice: true,
      isRemovable: true,
      canBecomeKitItem: true,
    };
  }

  // InventoryItem with parent and without children, is child of Option of kitItem
  if (
    item.item.itemType === productTypes.inventoryItem &&
    hasParent &&
    item.item.subItems.length === 0
  ) {
    return {
      isAssignable: true,
      isMutable: false,
      hasOwnPrice: false,
      isRemovable: false,
      canBecomeKitItem: false,
    };
  }

  // InventoryItem without parent, but with children, children can only be Options
  if (
    item.item.itemType === productTypes.inventoryItem &&
    !hasParent &&
    item.item.subItems.length > 0
  ) {
    return {
      isAssignable: true,
      isMutable: true,
      hasOwnPrice: true,
      isRemovable: true,
      canBecomeKitItem: false,
    };
  }

  // Option
  if (item.item.itemType === productTypes.optionItem) {
    return {
      isAssignable: false,
      isMutable: true,
      hasOwnPrice: true,
      isRemovable: true,
      canBecomeKitItem: false,
    };
  }

  // KitItem with a parent
  if (item.item.itemType === productTypes.kitItem && hasParent) {
    return {
      isAssignable: false,
      isMutable: false,
      hasOwnPrice: true,
      isRemovable: false,
      canBecomeKitItem: false,
    };
  }

  // KitItem without a parent
  if (item.item.itemType === productTypes.kitItem && !hasParent) {
    return {
      isAssignable: false,
      isMutable: true,
      hasOwnPrice: true,
      isRemovable: true,
      canBecomeKitItem: false,
    };
  }

  return baseActions;
};

/**
 * Setter function that sets several front-end exclusive properties to make handling
 * logic and displaying goodsItem data easier
 * @param {object} item The goodsItem
 * @param {object} parent The goodsItem that is the parent of the current goodsItem
 * the goodsItem, this value is used only on the front-end to help identifying the
 * type of goodsItem and display it or handle logic based on this type
 * @returns
 */
export const goodsItemDecorator = (item, parent = null) => {
  return {
    ...item,
    orderlineId: setOrderLineId(item),
    parent: parent ? parent.orderlineId : null,
    checked: false,
    selectedSupplierPurchaseOption: null,
    allowedActions: setAllowedActions(item, Boolean(parent)),
  };
};

/**
 * Helper function on the front-end that sets several additional
 * properties on goodsItems that makes handling them easier
 * @param {array} salesOrderGoodsItems Array of the goodsItems on the CSO
 * @returns {*}
 */
export const addPropsToGoodsItems = (salesOrderGoodsItems) => {
  const decorateGoodsItems = (items, parentItem) => {
    return items.map((item) => {
      item = goodsItemDecorator(item, parentItem);

      if (item.item.subItems.length > 0) {
        item.item.subItems = decorateGoodsItems(item.item.subItems, item);
      }

      return item;
    });
  };

  return decorateGoodsItems(salesOrderGoodsItems, null);
};

function setOrderLineId(item) {
  if (item.orderlineId === '00000000-0000-0000-0000-000000000000' || !item.orderlineId) {
    return uuidv4();
  } else {
    return item.orderlineId;
  }
}

import React, {useState} from 'react';
import {Button, Card} from 'react-bootstrap';
import {FaEdit} from 'react-icons/fa';
import {ShowCustomerInformation} from './show-customer-information';
import {EditCustomerInformation} from './edit-customer-information';

export const CustomerInformationCard = ({
  customerData,
  title,
  isBuyer,
  hasNetSuiteId,
}) => {
  const [edit, setEdit] = useState(false);

  return (
    <Card className="mb-2">
      <Card.Header>
        {title}
        <Button
          className="btn btn-light btn-sm float-right"
          size="sm"
          onClick={() => setEdit(!edit)}
          title={hasNetSuiteId ? 'Synced to NetSuite' : 'Edit'}
          disabled={hasNetSuiteId}
        >
          <FaEdit />
        </Button>
      </Card.Header>
      <Card.Body>
        {!edit ? (
          <ShowCustomerInformation
            customerData={customerData}
            title={title}
            toggleEdit={() => setEdit(!edit)}
            hasNetSuiteId={hasNetSuiteId}
            buyer={isBuyer}
          />
        ) : (
          <EditCustomerInformation customerData={customerData} isBuyer={isBuyer} />
        )}
      </Card.Body>
    </Card>
  );
};

import React from 'react';
import {FaExclamation, FaEye} from 'react-icons/fa';
import {any, bool, arrayOf, string} from 'prop-types';
import {NavLink} from 'react-router-dom';

export const ProductTitle = ({id, children, option}) => {
  const path = option
    ? `${process.env.REACT_APP_tafkap_url}product-options/${id}/edit`
    : `${process.env.REACT_APP_tafkap_url}products/${id}/edit`;

  return (
    <>
      {children}
      {id === '00000000-0000-0000-0000-000000000000' ? (
        <span>
          <FaExclamation color="red" title="ID is leeg" />
        </span>
      ) : (
        <NavLink
          className="ml-2"
          to={{pathname: path}}
          target="-blank"
          rel="noopener noreferrer"
        >
          <FaEye />
        </NavLink>
      )}
      <NavLink
        className="ml-2"
        to={`/productdetails/${id}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaEye color="red" />
      </NavLink>
    </>
  );
};

ProductTitle.propTypes = {
  id: string, // Gadero item object
  externalIds: arrayOf(string), // Gadero item object
  children: any, // Gadero item object
  isExternal: bool,
  option: bool,
};

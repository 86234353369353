import { number, string } from 'prop-types';
import React from 'react';

export const ProductCardTitle = ({ value, amount }) => {
  return (
    <>
      <span className="h5">{value}</span>
      <br />
      {amount && (
        <span>
          {amount} Product
          {amount > 1 && 'en'}
        </span>
      )}
    </>
  );
};

ProductCardTitle.propTypes = {
  value: string.isRequired,
  amount: number,
};

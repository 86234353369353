import React from 'react';

import {Button} from 'react-bootstrap';

/**
 * Small button with red outline that are used in a orderline
 * @param {{chilren : React.ReactNode, props: object} props
 * @returns
 */
export const ProductActionButton = ({children, ...props}) => {
  return (
    <Button
      className="ml-2 d-flex align-items-center"
      size="sm"
      variant="outline-danger"
      {...props}
    >
      {children}
    </Button>
  );
};

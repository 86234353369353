/**
 * Convert C# Ticks to javascript Date, then format it and return the resulting string.
 * @param ticks (Int64, or long, with nanotime from 1/1/1)
 * @returns {string}
 */
export const ticksToDateString = (ticks) => {
    // If ticks is set to MinValue (which is 0) in our API, it has no meaning to convert, so stop right away
    if (ticks === 0) return '-';

    // From dotnet docs: ( https://docs.microsoft.com/en-us/dotnet/api/system.datetime.ticks?view=net-5.0 )
    // A single tick represents one hundred nanoseconds or one ten-millionth of a second.
    // There are 10,000 ticks in a millisecond.
    // The value of ticks represents the number of 100-nanosecond intervals that have elapsed
    // since 12:00:00 midnight, January 1, 0001 in the Gregorian calendar, which represents MinValue.
    //
    // From mozilla docs: ( https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date )
    // A JavaScript date is fundamentally specified as the number of milliseconds that have elapsed since
    // midnight on January 1, 1970, UTC.
    //
    // So now we know what both values are, convert unit from 1/100th nanosecond -> milliseconds.
    let ticksToMilliSeconds = ticks / 10000;

    // Next step, find the difference of the starting point, from which both systems start counting their units.
    //
    // C# ticks are recorded from 1/1/1
    // Javascript starts counting from 1/1/1970
    //
    // So now get millisecond diff from 1/1/1/ to 1/1/1970:
    // We do this by creating a new javascript Date and explicitly setting it to 1/1/1, setFullYear will return us
    // the new timestamp relative to 1/1/1970. The timestamp is negative, since 1/1/1 is before 1/1/1970, so we want
    // the abs value so we can easily use it as a diff.
    let milliSecondsDiff = Math.abs(new Date(0, 0, 1).setFullYear(1));

    // Now create the new Date from the converted milliseconds, minus the difference in milliseconds of the two
    // counting systems.
    //
    // So basically:
    // ticksToMilliSeconds = ms from 1/1/1 until <timestamp>.
    // milliSecondsDiff = ms from 1/1/1 until 1/1/1970
    // new Date(timestamp) = ms from 1/1/1970 until <timestamp>.
    // -> So finally: we want ms without the part before 1970, hence the subtraction here
    //
    // 1/1/1 --------------------- 1/1/1970 --------------- timestamp
    //   [  BAD! (=milliSecondsDiff)  ][          GOOD!         ]
    //
    let date = new Date(ticksToMilliSeconds - milliSecondsDiff);

    // Conversion from C# Ticks to Javascript ms is easy!
    return formatDate(date);
};

export const formatDate = (date) => {
    return [
        date.getFullYear(),
        ('0' + (date.getMonth() + 1)).slice(-2),
        ('0' + date.getDate()).slice(-2),
    ].join('-');
};

import {Col, Form, FormControl, InputGroup, Row} from 'react-bootstrap';
import React, {FC} from 'react';

import Select from 'react-select';
import {countryOptions} from './country-config';

interface IProps {
  street: string;
  number: string;
  numberAddition: string;
  place: string;
  countryCode: string;
  zip: string;
  currency: string;
  shippingCost: number;
  setShippingCost: React.Dispatch<React.SetStateAction<any>>;
  setCurrency: React.Dispatch<React.SetStateAction<any>>;
  setStreet: React.Dispatch<React.SetStateAction<any>>;
  setZip: React.Dispatch<React.SetStateAction<any>>;
  setNumberAddition: React.Dispatch<React.SetStateAction<any>>;
  setPlace: React.Dispatch<React.SetStateAction<any>>;
  setCountryCode: React.Dispatch<React.SetStateAction<any>>;
  setNumber: React.Dispatch<React.SetStateAction<any>>;
}

export const inputStyles = {
  borderRadius: '4px 0px 0px 4px',
  borderRight: '0px',
  color: 'gray',
};

export const DeliveredForm: FC<IProps> = ({
  street,
  number,
  numberAddition,
  place,
  countryCode,
  zip,
  setStreet,
  setNumber,
  setNumberAddition,
  setCountryCode,
  setPlace,
  setZip,
  shippingCost,
  setShippingCost,
}) => {
  return (
    <Row>
      <Col className="col-8">
        <InputGroup className="mb-3">
          <InputGroup.Text style={inputStyles}>Shipping costs</InputGroup.Text>
          <Form.Control
            type="number"
            value={shippingCost}
            onChange={(e) => setShippingCost(parseFloat(e.target.value))}
          />
        </InputGroup>
      </Col>
      <Col className="col-4" style={{paddingLeft: '0px'}}></Col>
      <Col>
        <label>Delivery address:</label>
        <InputGroup className="mb-2">
          <InputGroup.Text style={inputStyles}>Street</InputGroup.Text>
          <Form.Control
            type="text"
            value={street}
            onChange={(e) => setStreet(e.target.value)}
          />
        </InputGroup>
        <Row>
          <Col style={{paddingRight: '10px'}}>
            <InputGroup className="mb-2">
              <InputGroup.Text style={inputStyles}>House number</InputGroup.Text>
              <Form.Control
                type="text"
                value={number}
                onChange={(e) => setNumber(e.target.value)}
              />
            </InputGroup>
          </Col>
          <Col className="col-5" style={{paddingLeft: '0px'}}>
            <InputGroup className="mb-2">
              <InputGroup.Text style={inputStyles}>Number addition</InputGroup.Text>
              <FormControl
                type="text"
                value={numberAddition}
                onChange={(e) => setNumberAddition(e.target.value)}
              />
            </InputGroup>
          </Col>
        </Row>
        <InputGroup className="mb-2">
          <InputGroup.Text style={inputStyles}>Zip</InputGroup.Text>
          <Form.Control
            type="text"
            value={zip}
            onChange={(e) => setZip(e.target.value)}
          />
        </InputGroup>
        <InputGroup className="mb-2">
          <InputGroup.Text style={inputStyles}>City</InputGroup.Text>
          <Form.Control
            type="text"
            value={place}
            onChange={(e) => setPlace(e.target.value)}
          />
        </InputGroup>
        <InputGroup className="mb-2">
          <InputGroup.Text style={inputStyles}>Country</InputGroup.Text>
          <div className={'d-flex, flex-grow-1'}>
            <Select
              isClearable
              value={countryOptions.filter(function (option) {
                return option.value === countryCode;
              })}
              options={countryOptions}
              onChange={(e) => setCountryCode(e ? e.value : '')}
            />
          </div>
        </InputGroup>
      </Col>
    </Row>
  );
};

import {v4 as uuidv4} from 'uuid';

import {determineItemTypeOnPimProduct} from '../determineItemTypeOnPimProduct';

/**
 * Takes in a PIM product and transforms it into a goodsItem like it is on the CSO
 * @param {object} item The PIM product
 * @param {number | string} amount The amount of the product
 * @returns
 */
export const transformPIMProductToGoodsItem = (item, amount) => {
  return {
    assignedPurchases: [],
    checked: false,
    fullyAssigned: false,
    isCustomProduct: false,
    price: {
      vatPercentage: item.locales['nl_NL'].price.vatPercentage,
      valueIncludesVat: item.locales['nl_NL'].price.valueIncludesVat,
      vatExempted: item.locales['nl_NL'].price.vatExempted,
      currency: '€',
      value: item.locales['nl_NL'].price.value,
    },
    quantity: {
      unit: item.properties?.unit || '',
      value: amount,
    },
    item: {
      dimensions: {},
      ean: item.ean,
      id: item.productId,
      externalIds: item.externalIds,
      productNumber: item.externalIds
        ?.filter((id) => id.includes('articlenumber'))[0]
        ?.slice(24),
      name: item.locales['nl_NL'].name,
      subItems: item.subProducts,
      itemType: determineItemTypeOnPimProduct(item),
      sourceId: '',
    },
    orderlineId: uuidv4(),
  };
};

import React from 'react';

import ProductOptionParentRow from '../../pages/ClientSalesOrderEdit/UnassignedProducts/ProductOptionParentRow';
import AssignableProductRow from '../../pages/ClientSalesOrderEdit/UnassignedProducts/AssignableProductRow';
import {KitItemRow} from '../../pages/ClientSalesOrderEdit/UnassignedProducts/KitItemRow';
import {productTypes} from '../../../utils/determineItemTypeOnPimProduct';

export const ProductRowType = ({item, orderLinesObject, childrenDictionary}) => {
  // kitItem that is not a child of an option
  if (
    item.item?.itemType === productTypes.kitItem &&
    !Object.values(childrenDictionary).some((el) => el.includes(item.orderlineId))
  ) {
    return (
      <KitItemRow
        item={item}
        orderLinesObject={orderLinesObject}
        childrenDictionary={childrenDictionary}
        level={0}
      />
    );
  }

  // inventoryItem with options
  if (
    item.item?.itemType === productTypes.inventoryItem &&
    item.item?.subItems.length > 0
  ) {
    return (
      <ProductOptionParentRow
        item={item}
        orderLinesObject={orderLinesObject}
        childrenDictionary={childrenDictionary}
      />
    );
  }

  // inventoryItem without options
  if (
    item.item?.itemType === productTypes.inventoryItem &&
    item.item?.subItems.length === 0 &&
    !Object.values(childrenDictionary).some((el) => el.includes(item.orderlineId))
  ) {
    return <AssignableProductRow item={item} />;
  }

  return null;
};

import axios from 'axios';
import QueryString from 'qs';
import jwt_decode from 'jwt-decode';

const SEARCH_API_URL = process.env.REACT_APP_search_api_url;
const ORDERS_API_URL = process.env.REACT_APP_orders_api_url;
const READ_ORDERS_API_URL = process.env.REACT_APP_read_orders_api_url;
const PIM_SEARCH_URL = process.env.REACT_APP_pim_search_url;
const PIM_READ_URL = process.env.REACT_APP_pim_read_api;
const SUPPLIERS_READ_API = process.env.REACT_APP_suppliers_api_url;
const SOAPI_READ_API = process.env.REACT_APP_soapi_api_url;

function upgradeDataWithUserIdFromHeader(data) {
  let authHeader = axios.defaults.headers.common['Authorization'];

  if (authHeader) {
    //add user id to the data
    const decoded = jwt_decode(authHeader.substring(7)); //remove "bearer "
    data.userId = decoded.sub;
  }
}

export const getClientSalesOrders = (filters, minResults, maxResults) => {
  const queryParams = QueryString.stringify(
    {
      ...filters,
      minResults,
      maxResults,
    },
    {skipNulls: true}
  );

  return axios.get(`${SEARCH_API_URL}/search/clientsalesorders?${queryParams}`);
};

export const getRegions = () => {
  return axios.get(`${SEARCH_API_URL}/search/regions`);
};

export const getSalesOrdersDetails = (id) => {
  return axios.get(
    `${READ_ORDERS_API_URL}/salesorder/salesOrderDetails?salesOrderId=${id}`
  );
};

export const GetShippingMethods = () => {
  return axios.get(`${SEARCH_API_URL}/search/shippingMethods`);
};

export const updateGoodsItems = (data) => {
  upgradeDataWithUserIdFromHeader(data);

  return axios.post(`${ORDERS_API_URL}salesorder/updateGoodsItems`, data);
};

export const getProductDetailsFromPIM = (id) => {
  return axios.get(`${PIM_READ_URL}details?productId=${id}`);
};

export const getProductsFromPIM = ({query, locale, productType, option}) => {
  const adjustedQuery = query ? query : '*:*';
  const adjustedLocale = locale ? '&locale=' + locale : '&locale=nl_NL';
  const adjustedProductType = productType ? '&productType=' + productType : '';
  const adjustedOption = option ? '&options=true' : '';

  return axios.get(
    `${PIM_SEARCH_URL}api/search?query=` +
      adjustedQuery +
      adjustedLocale +
      adjustedProductType +
      adjustedOption
  );
};

export const postDeadline = (data) => {
  data.ETag = new Date().toJSON();
  upgradeDataWithUserIdFromHeader(data);

  return axios.post(`${ORDERS_API_URL}purchaseorder/updateDeadline`, data);
};

export const postSellerComment = (data) => {
  upgradeDataWithUserIdFromHeader(data);

  return axios.post(`${ORDERS_API_URL}salesorder/addSellerComment`, data);
};

export const fetchTransporters = () => {
  return axios.get(`${READ_ORDERS_API_URL}/purchaseorder/transporters`);
};

export const getSuppliersFromInkoop = () => {
  return axios.get(`${SUPPLIERS_READ_API}api/supplier`);
};

export const postOrderStatus = (data) => {
  upgradeDataWithUserIdFromHeader(data);

  return axios.post(`${ORDERS_API_URL}salesorder/setStatus`, data);
};

export const getProductStockInformation = (productId) => {
  return axios.get(`${PIM_READ_URL}productSummary?productId=${productId}`);
};

export const getSoapiDetails = (id, shippingMethod, pickupLocation) => {
  return axios.get(
    `${SOAPI_READ_API}Soapi/ShippingOption?orderId=${id}&shippingMethodString=${shippingMethod}&pickupLocation=${pickupLocation}`
  );
};

import {paymentTypes as types} from '../utils/data/paymentTypes';

/**
 * Takes the data regarding payments and shipping from the different places on
 * the CSO and creates an object with them for easier use
 * @param {object} salesOrder The CSO
 * @returns {{currency: string, totalPayments: number, totalRefunds: number, totalDiscount: number, paymentMethodCost: number, shippingCost: number}} */
export const convertDataIntoPaymentsObject = (salesOrder) => {
  let object = {
    currency: '',
    totalPayments: 0,
    totalRefunds: 0,
    totalDiscount: 0,
    paymentMethodCost: 0,
    shippingCost: 0,
  };

  salesOrder.payments?.forEach((payment) => {
    if (payment.$type === types.payment) {
      object.currency = payment.amountPaid?.currency;

      if (payment.paymentMethodCost) {
        object.paymentMethodCost += payment.paymentMethodCost?.value;
      }

      object.totalPayments += payment.amountPaid?.value;
    }

    if (payment.$type === types.discount) {
      object.totalDiscount += payment.amountPaid?.value ?? 0;
    }

    if (payment.$type === types.refund) {
      object.totalRefunds += payment.amountPaid?.value;
    }
  });
  object.shippingCost = salesOrder.shipping?.shippingCost?.value;

  return object;
};

import React, {FC, useCallback, useMemo, useState} from 'react';
import {useForm} from 'react-hook-form';

import {Modal, Button} from 'react-bootstrap';
import Select from 'react-select';
import {FaTimes} from 'react-icons/fa';

import deliveryOptions from './deliveryOptions';
import {notifyError, notifySuccess} from '../../../../utils/toasts';
import {
  changeShippingMethod,
  updateReceiverInfo,
} from '../../../../apis/postApis/postApis';
import {
  IAddress,
  ISetShippingInfoPostObject,
} from '../../../../apis/postApis/postApis.types/setShippingInfoPostObject';
import {IChangeReceiverInfoPostObject} from '../../../../apis/postApis/postApis.types/changeReceiverInfoPostObject.type';
import {ICSODetails} from '../../../../apis/getApis/clientSalesOrders.types';
import {LocalPickUpForm} from './LocalPickUpForm';
import {DeliveredForm} from './DeliveredForm';

import {countryCodeDictionary} from './country-config';
import {
  localPickupLocationOptions,
  localPickUpLocations,
} from './localPickupLocationsConfig';

interface Props {
  handleClose: () => void;
  orderId: string;
  salesOrder: ICSODetails;
}

const ChangeShippingModal: FC<Props> = ({handleClose, orderId, salesOrder}) => {
  const {shipping} = salesOrder;

  const [checked, setChecked] = useState(false);
  const [shippingMethod, setShippingMethod] = useState<
    typeof deliveryOptions[number]['value'] | null
  >(null);
  const [street, setStreet] = useState(shipping?.deliveryAddress?.street || '');
  const [number, setNumber] = useState(shipping?.deliveryAddress?.number || '');
  const [numberAddition, setNumberAddition] = useState(
    shipping?.deliveryAddress?.numberAddition || ''
  );
  const [place, setPlace] = useState(shipping?.deliveryAddress?.place || '');
  const [zip, setZip] = useState(shipping?.deliveryAddress?.zip || '');
  const [localPickUpLocation, setLocalPickupLocation] = useState<string | null>('');
  const [countryCode, setCountryCode] = useState(
    shipping?.deliveryAddress?.countryCode?.toUpperCase() || ''
  );
  const [shippingCost, setShippingCost] = useState(shipping?.shippingCost?.value || 0);
  const [currency, setCurrency] = useState('EUR');
  const {handleSubmit} = useForm({
    defaultValues: {shippingMethod: shippingMethod, shippingAddress: ''},
  });

  const localPickupLocationAddress = useMemo(() => {
    return localPickUpLocations.find((el) => el.place === localPickUpLocation);
  }, [localPickUpLocation]);

  const setCorrectAddress = (): IAddress => {
    if (shippingMethod === 'LocalPickUp') {
      return {
        place: localPickupLocationAddress?.place || '',
        zip: localPickupLocationAddress?.zip || '',
        street: localPickupLocationAddress?.street || '',
        country: localPickupLocationAddress?.country || '',
        countryCode: localPickupLocationAddress?.countryCode || '',
        number: localPickupLocationAddress?.number || '',
        numberAddition: localPickupLocationAddress?.numberAddition || '',
      };
    }

    return {
      street,
      number,
      numberAddition,
      zip,
      place,
      country: countryCodeDictionary[countryCode as keyof typeof countryCodeDictionary],
      countryCode,
    };
  };

  const setShippingMethodPostObject = (): ISetShippingInfoPostObject => {
    return {
      orderId: orderId,
      userId: '',
      eTag: new Date().toJSON(),
      orderType: 1,
      shipping: {
        shippingCost: {
          currency: currency,
          value: shippingCost,
        },
        deliveryAddress: setCorrectAddress(),
        shippingMethod: shippingMethod as string,
      },
    };
  };

  const changeReceiverInfoPostObject = (): IChangeReceiverInfoPostObject => {
    return {
      orderId: orderId,
      receiver: {
        contact: salesOrder.receiver.contact,
        address: {
          latLong: {
            latitude: 1,
            longitude: 1,
          },
          ...setCorrectAddress(),
        },
      },
      userId: '',
      eTag: new Date().toJSON(),
      orderType: 'SalesOrder',
    };
  };

  const handlePostData = async () => {
    const shippingMethodPostObject = setShippingMethodPostObject();

    if (shippingMethod === null) {
      notifyError('No shipping method selected!');
    } else if (shippingMethod === 'LocalPickUp' && !localPickUpLocation) {
      notifyError('Select a location');
    } else {
      try {
        const response = await changeShippingMethod(shippingMethodPostObject);

        if (response.data.success) {
          notifySuccess('Successfully changed shipping method.');
          handleClose();
          // window.localPickUpLocation.reload();
        } else {
          notifyError('Something went wrong, contact IT if it persists');
        }
      } catch (error) {
        notifyError('Something went wrong, contact IT if it persists');
      }
    }
  };

  const handleReceiverPostData = async () => {
    const receiverInfoPostObject = changeReceiverInfoPostObject();

    try {
      const response = await updateReceiverInfo(receiverInfoPostObject);

      if (response.data.success) {
        notifySuccess('Ontvangstadres succesvol gewijzigd.');
      } else {
        notifyError('Something went wrong, contact IT if it persists');
      }
    } catch (error) {
      notifyError('Something went wrong, contact IT if it persists');
    }
  };

  const handleOnChange = () => {
    setChecked(!checked);
  };

  const handleChangePickUpLocation = useCallback((localPickUpLocation: string | null) => {
    setLocalPickupLocation(localPickUpLocation);
  }, []);

  const onSubmit = () => {
    if (checked) {
      handlePostData().then(() => handleReceiverPostData());
    } else {
      handlePostData();
    }
  };

  return (
    <>
      <Modal.Header>
        <Modal.Title className="font-weight-light">Change shipping</Modal.Title>
        <Button variant="light" onClick={handleClose}>
          <FaTimes style={{color: 'lightgray'}} />
        </Button>
      </Modal.Header>
      <Modal.Body className="p-4">
        <form onSubmit={handleSubmit(onSubmit)}>
          <label>Delivery method:</label>
          <Select
            className={'mb-4'}
            isSearchable
            isClearable
            options={deliveryOptions}
            onChange={(e) => setShippingMethod(e ? e.value : null)}
          />

          {shippingMethod === 'LocalPickUp' ? (
            <>
              <label>Locatie:</label>
              <Select
                options={localPickupLocationOptions}
                className={'mb-4'}
                isSearchable
                isClearable
                onChange={(el) => {
                  handleChangePickUpLocation(el ? el.value : null);
                }}
              />
              {localPickUpLocation && (
                <LocalPickUpForm
                  localPickUpLocationAddress={localPickupLocationAddress}
                />
              )}
            </>
          ) : ['Delivered', 'Express'].includes(shippingMethod ?? '') ? (
            <DeliveredForm
              zip={zip}
              place={place}
              street={street}
              number={number}
              countryCode={countryCode}
              currency={currency}
              shippingCost={shippingCost}
              numberAddition={numberAddition}
              setZip={setZip}
              setPlace={setPlace}
              setNumber={setNumber}
              setStreet={setStreet}
              setCountryCode={setCountryCode}
              setCurrency={setCurrency}
              setShippingCost={setShippingCost}
              setNumberAddition={setNumberAddition}
            />
          ) : null}
          <label>
            <input type="checkbox" className="mr-2 mt-2" onChange={handleOnChange} />
            Copy data to delivery address
          </label>
          <hr />
          <Button
            type="submit"
            className="btn btn-success ml-1 float-right"
            title="Submit"
          >
            Submit
          </Button>
        </form>
      </Modal.Body>
    </>
  );
};

export default ChangeShippingModal;

import React from 'react';
import {Navbar, Nav} from 'react-bootstrap';
import {NavLink} from 'react-router-dom';
import './Navigation.css';

const Navigation = () => {
  return (
    <Navbar className="navbar navbar-dark mb-2" expand="md">
      <Navbar.Brand href="/">
        <img className="mr-2" alt="logo" src={'/img/egel_gadero_wit.png'} />
        Order processing
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto">
          <Nav.Link as={NavLink} className="navbar-item" to="/">
            Homepage
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Navigation;

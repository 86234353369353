const loggerMiddleware = (store) => (next) => (action) => {
  if (process.env.REACT_APP_LOG_REDUX === 'true') {
    // eslint-disable-next-line
    console.log('Action type:', action.type);
    // eslint-disable-next-line
    console.log('Action payload:', action.payload);
    // eslint-disable-next-line
    console.log('State before:', store.getState());
  }
  next(action);

  if (process.env.REACT_APP_LOG_REDUX === 'true')
    // eslint-disable-next-line
    console.log('State after:', store.getState());
};

export default loggerMiddleware;

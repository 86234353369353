import React, {useCallback, useEffect, useState} from 'react';
import {Button, Col, Row} from 'react-bootstrap';
import {useDispatch} from 'react-redux';

import {OrderWrapper} from '../../../shared/product/product-row-components';
import {fetchTransporters} from '../../../../apis/ApiCalls';
import {setLoading} from '../../../../redux/actions/actions';
import Stack from '../../../shared/layout/stack/stack';

import PurchaseOrderCard from './PurchaseOrderCard';

export const PurchaseOrders = ({purchaseOrders}) => {
  const [transporterOptions, setTransporterOptions] = useState([]);
  const dispatch = useDispatch();
  const purchaseOrdersLength = purchaseOrders.length > 0;

  const stableSetLoading = useCallback((args) => dispatch(setLoading(args)), [dispatch]);

  useEffect(() => {
    stableSetLoading(true);
    let mounted = true;

    const getTransporters = async () => {
      try {
        const {data} = await fetchTransporters();

        if (data && mounted) {
          const transporterOptions = data.map((item) => {
            return {
              label: item.contact?.name,
              value: item.reference,
            };
          });

          setTransporterOptions(transporterOptions);
        }
      } catch (error) {
        console.error('Error trying to fetch transporters: ', error);
      }
    };

    if (purchaseOrdersLength) {
      getTransporters();
    }

    stableSetLoading(false);

    return function cleanUp() {
      mounted = false;
    };
  }, [stableSetLoading, purchaseOrdersLength]);

  const sortedOrders = purchaseOrders.reduce(
    (result, item) => {
      if (item.isSentToSupplier) {
        result.sent.push(item);
      } else {
        result.pending.push(item);
      }

      return result;
    },
    {sent: [], pending: []}
  );

  return (
    <OrderWrapper>
      <Row className="d-flex justify-content-between align-items-center">
        <Col>
          <span className="h5 bold">Purchase orders</span>
        </Col>
        <Col md={3}>
          {sortedOrders.pending.length > 0 && (
            <Button
              disabled
              className="ml-2"
              variant="outline-danger"
              size="sm"
              title={'Send purchase orders to suppliers'}
            >
              Orders Versturen
            </Button>
          )}
        </Col>
      </Row>
      <hr />
      {sortedOrders.pending.some((item) => item.goodsItems.length > 0) && (
        <Stack>
          <h5>Status: open</h5>
          {sortedOrders.pending.map((item, i) => {
            if (item.goodsItems.length === 0) {
              return null;
            }

            return (
              <PurchaseOrderCard
                key={i}
                purchaseOrder={item}
                transporterOptions={transporterOptions}
              />
            );
          })}
        </Stack>
      )}
      <br />
      {sortedOrders.sent.some((item) => item.goodsItems.length > 0) && (
        <Stack>
          <h5>Status: sent</h5>
          {sortedOrders.sent.map((item, i) => {
            if (item.goodsItems.length === 0) {
              return null;
            }

            return (
              <PurchaseOrderCard
                key={i}
                purchaseOrder={item}
                transporterOptions={transporterOptions}
              />
            );
          })}
        </Stack>
      )}
    </OrderWrapper>
  );
};

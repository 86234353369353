import {IAddress} from '../../../../apis/postApis/postApis.types/setShippingInfoPostObject';

export const localPickUpLocations: IAddress[] = [
  {
    street: 'Straatweg',
    number: '5',
    numberAddition: '',
    zip: '3621 BG',
    place: 'Breukelen',
    country: 'Nederland',
    countryCode: 'NL',
  },
  {
    street: 'Euvelgunnerweg',
    number: '19',
    numberAddition: '',
    zip: '9723 CT',
    place: 'Groningen',
    country: 'Nederland',
    countryCode: 'NL',
  },
  {
    street: 'Hekkestraat',
    number: '20',
    numberAddition: '',
    zip: '9308',
    place: 'Hofstade-Aalst',
    country: 'België',
    countryCode: 'BE',
  },
  {
    street: 'Rue Pierre Simon de Laplace',
    number: '10',
    numberAddition: '',
    zip: '79000',
    country: 'Frankrijk',
    place: 'Niort',
    countryCode: 'FR',
  },
  {
    street: 'De Run',
    number: '4444',
    numberAddition: '',
    zip: '5503 LR',
    place: 'Veldhoven',
    country: 'Nederland',
    countryCode: 'NL',
  },
  {
    street: 'Van Hilstraat',
    number: '25',
    numberAddition: '',
    zip: '5145 RK',
    place: 'Waalwijk',
    country: 'Nederland',
    countryCode: 'NL',
  },
  {
    street: 'Scheidersweg',
    number: '2',
    numberAddition: 'A',
    zip: '1731 LX',
    place: 'Winkel',
    country: 'Nederland',
    countryCode: 'NL',
  },
];

export const localPickupLocationOptions = localPickUpLocations.map((location) => ({
  label: location.place,
  value: location.place,
}));

import React from 'react';
import {any} from 'prop-types';

export const TotalPriceDifference = ({item, costPriceSumChildren}) => {
  const totalSellPrice = Number(item.quantity.value) * Number(item.price.value);
  let totalCostPrice = 0;

  if (item.selectedSupplier) {
    totalCostPrice =
      Number(item.quantity.value) * Number(item.selectedSupplier.costPrice.value);
  }

  const difference = totalSellPrice - totalCostPrice;

  if (['isNormal', 'hasOption'].includes(item.goodType)) {
    return (
      <p
        style={{
          ...(difference === totalSellPrice && {
            display: 'none',
          }),
        }}
        className={'mt-0 text-right ' + (difference > 0 ? 'text-success' : 'text-danger')}
      >
        Difference{' '}
        <span>
          {item.price.currency} {difference.toFixed(2)}
        </span>
      </p>
    );
  } else if (costPriceSumChildren) {
    return (
      <p
        style={{
          ...(totalSellPrice - costPriceSumChildren === totalSellPrice && {
            display: 'none',
          }),
        }}
        className={
          'mt-0 text-right ' +
          (totalSellPrice - costPriceSumChildren > 0 ? 'text-success' : 'text-danger')
        }
      >
        Difference{' '}
        <span>
          {item.price.currency} {(totalSellPrice - costPriceSumChildren).toFixed(2)}
        </span>
      </p>
    );
  }

  return null;
};

TotalPriceDifference.propTypes = {
  item: any, // Gadero item object
};

import React, {useCallback, useEffect, useState} from 'react';
import {Container} from 'react-bootstrap';
import {useParams} from 'react-router-dom';

import LoadingSpinner from '../../shared/LoadingSpinner';
import {getProductStockInformation} from '../../../apis/ApiCalls';

import StockLocation from './StockLocation';
import MainInformationTable from './MainInformationTable';
import ProductDetailsCard from './ProductDetailsCard';

const ProductStockInformationPage = () => {
  const [productDetails, setProductDetails] = useState({});
  const {productId} = useParams();
  const [loading, setLoading] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState('Groningen');

  const fetchProductDetails = useCallback(async () => {
    setLoading(true);

    const {data} = await getProductStockInformation(productId);

    if (data) {
      setProductDetails(data);
      setLoading(false);
    }
  }, [productId]);

  useEffect(() => {
    fetchProductDetails();
  }, [fetchProductDetails, productId]);

  const handleLocationSelect = (location) => {
    setSelectedLocation(location);
  };

  if (loading) {
    return <LoadingSpinner inline={false} loading={loading} />;
  } else if (Object.keys(productDetails).length > 0) {
    return (
      <Container className="mb-5 px-5">
        <ProductDetailsCard productDetails={productDetails} />
        {productDetails.stock?.length > 0 ? (
          <>
            <MainInformationTable
              productDetails={productDetails}
              handleLocationSelect={handleLocationSelect}
              selectedLocation={selectedLocation}
            />
            {productDetails.stock.some(
              (stockLocation) => stockLocation.location === selectedLocation
            ) && (
              <StockLocation
                stockLocation={productDetails.stock.find(
                  (stockLocation) => stockLocation.location === selectedLocation
                )}
              />
            )}
          </>
        ) : (
          <p>No information available for this product</p>
        )}
      </Container>
    );
  } else {
    return null;
  }
};

export default ProductStockInformationPage;

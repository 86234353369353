/**
 * Takes the goodsItems from the CSO and makes the dictionary that will be used
 * to navigate through the orderlinesObject
 * @param {array} goodsItems The goodsItems from the CSO
 * @returns {{}}
 */
export const buildChildrenDictionary = (goodsItems) => {
  const childrenDictionary = {};

  const processItems = (items) => {
    items.forEach((item) => {
      if (item.item.subItems?.length > 0) {
        const childIds = item.item.subItems.map((subItem) => subItem.orderlineId);
        childrenDictionary[item.orderlineId] = childIds;
        processItems(item.item.subItems);
      }
    });
  };

  processItems(goodsItems);

  return childrenDictionary;
};

import styled from 'styled-components';

export interface BoxProps {
  padding?: string;
  borderWidth?: string;
  inverted?: boolean;
  hideBackground?: boolean;
}

export const Box = styled.div<BoxProps>`
  padding: ${props => props.padding || 'var(--s1)'};
  border: ${props => props.borderWidth || 'none'} solid
    ${props => (props.inverted ? 'var(--box-color-light)' : 'var(--box-color-dark)')};
  color: ${props => (props.inverted ? 'var(--box-color-light)' : 'var(--box-color-dark)')};
  background-color: ${props =>
    !props.hideBackground
      ? props.inverted
        ? 'var(--box-color-dark)'
        : 'var(--box-color-light)'
      : 'none'};

  & * {
    color: inherit;
  }
`;

export default Box;

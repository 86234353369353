import React, {useState} from 'react';
import {Button, Card, FormControl, InputGroup} from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import Collapsible from 'react-collapsible';

import {getCurrentDeliveryFromPO} from '../../../../utils/delivery/deliveryFunctions';
import {initialShippingMethod} from '../../../../utils/data/initialShippingMethod';
import {deliveryMethods} from '../../../../utils/delivery/deliveryMethods';
import Box from '../../../shared/layout/box/box';
import Cluster from '../../../shared/layout/cluster/cluster';
import Stack from '../../../shared/layout/stack/stack';

import PurchaseOrderLinesTable from './PurchaseOrderLinesTable';
import {
  BlockLabel,
  BottomBox,
  Colli,
  DeliveryCustomer,
  DeliveryGadero,
  DeliveryMethod,
  OrderDetails,
  PurchaseOrderCardHeader,
  PurchaseOrderGrid,
  TntUrl,
  Transporter,
} from './PurchaseOrderCardGrid';

const OM_ORDERS_URL = process.env.REACT_APP_om_orders_url;

const PurchaseOrderCard = ({purchaseOrder, transporterOptions}) => {
  const {
    goodsItems,
    buyerReference,
    sellerReference,
    seller,
    expectedDeliveryDate,
    expectedArrivalDate,
    status,
    colliCount,
    transports,
  } = purchaseOrder;

  const [isOpen, setIsOpen] = useState(false);
  const [tntUrl, setTntUrl] = useState(
    transports.length > 0 ? transports[transports.length - 1].trackAndTraceUrl : ''
  );

  const isBeingPicked =
    status === 'PickingStarted' ||
    status === 'PickingDone' ||
    status === 'PickingPaused' ||
    status === 'PickingResumed';

  const totalPrice = goodsItems.reduce(
    (previousValue, currentValue) =>
      previousValue + currentValue.quantity.value * currentValue.price.value,
    0
  );

  return (
    <Card>
      <PurchaseOrderCardHeader pickingstatus={status}>
        <Cluster justify="space-between" align="center">
          <strong>{seller.contact.name}</strong>
          <a rel="noreferrer" target="_blank" href={`${OM_ORDERS_URL}${buyerReference}`}>
            {buyerReference}
          </a>
          <span>{sellerReference || ''}</span>
        </Cluster>
      </PurchaseOrderCardHeader>
      <Card.Body style={{padding: '0'}}>
        <Box padding="1rem">
          <Stack>
            <PurchaseOrderGrid>
              <DeliveryGadero>
                <BlockLabel> Arrival Gadero </BlockLabel>
                {initialShippingMethod.value !== 'direct-to-consumer' && (
                  <DatePicker
                    popperPlacement="bottom-end"
                    dateFormat="dd-MM-yyyy"
                    isClearable
                    selected={expectedArrivalDate ? new Date(expectedArrivalDate) : null}
                    disabled
                  />
                )}
              </DeliveryGadero>
              <DeliveryCustomer>
                <BlockLabel> Delivery customer </BlockLabel>
                <DatePicker
                  popperPlacement="bottom-end"
                  dateFormat="dd-MM-yyyy"
                  selected={expectedDeliveryDate ? new Date(expectedDeliveryDate) : null}
                  isClearable
                  disabled
                />
              </DeliveryCustomer>
              <OrderDetails>
                <p className="h6 text-right">
                  <strong>€ {totalPrice.toFixed(2)}</strong>
                  <br />
                  <small className="text-right mb-0">
                    {goodsItems.length} Product{goodsItems.length > 1 && 'en'}
                  </small>
                </p>
              </OrderDetails>
              <DeliveryMethod>
                <BlockLabel>Shipping method</BlockLabel>
                <Select
                  menuPortalTarget={document.body}
                  className="select"
                  placeholder="Select shipping method"
                  options={deliveryMethods}
                  defaultValue={getCurrentDeliveryFromPO(purchaseOrder)}
                  isDisabled
                />
              </DeliveryMethod>
              <Transporter>
                {!['Delivered', 'LocalPickup'].includes(initialShippingMethod.method) && (
                  <>
                    <BlockLabel>Transporteur</BlockLabel>
                    <Select
                      menuPortalTarget={document.body}
                      placeholder="Select transporter"
                      options={transporterOptions}
                      value={transporterOptions.filter(
                        (option) => option.value === purchaseOrder.transporter?.reference
                      )}
                      isDisabled
                    />
                  </>
                )}
              </Transporter>
              <Colli>
                <BlockLabel>Colli</BlockLabel>
                <Select
                  menuPortalTarget={document.body}
                  placeholder=""
                  value={{value: colliCount, label: colliCount}}
                  isDisabled
                />
              </Colli>
              <TntUrl>
                <BlockLabel>T&T url</BlockLabel>
                <InputGroup className="mb-3">
                  <FormControl
                    disabled
                    onChange={(e) => setTntUrl(e.target.value)}
                    value={tntUrl}
                    placeholder="https://www.example.nl"
                  />
                </InputGroup>
              </TntUrl>
            </PurchaseOrderGrid>
            <Cluster justify="flex-end">
              <Button variant="primary" onClick={() => setIsOpen(!isOpen)}>
                {isOpen ? 'Hide details' : 'Show details'}
              </Button>
            </Cluster>
          </Stack>
        </Box>
      </Card.Body>
      <BottomBox padding="1rem">
        <Collapsible
          trigger={''}
          open={isOpen}
          transitionTime={200}
          overflowWhenOpen="inherit"
        >
          <PurchaseOrderLinesTable
            purchaseOrder={purchaseOrder}
            immutable={isBeingPicked}
          />
        </Collapsible>
      </BottomBox>
    </Card>
  );
};

export default PurchaseOrderCard;

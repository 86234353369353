import {v4 as uuidv4} from 'uuid';

import store from '../../../redux/store';
import {setAmountOrRemoveItem} from '../../orderLines/setAmountOrRemoveItem';
import {deleteTypesFromGoodsItems} from '../../orderLines/deleteTypesFromGoodsItems';
import {updateGoodsItems} from '../../../apis/ApiCalls';
import {notifyError, notifyWarning} from '../../toasts';
import {refreshCSO} from '../refreshCSO';
import {rebuildSalesOrderGoodsItems} from '../../orderLines/transformSalesOrderGoodsItemsToOrderLines';

/**
 * Remove an amount of products from the cso, if the amount is equal or greater
 * then the total amount of the goodsItem, the item is removed entirely
 * @param {selectedProduct} selectedProduct Gadero goodsitem
 * @param {number} amount The value that will be subtracted from the goodsItem
 */
export async function removeProductFromSalesOrder(selectedProduct, amount) {
  const childrenDictionary = store.getState().childrenDictionary;

  const tempArray = rebuildSalesOrderGoodsItems(
    store.getState().orderLines,
    childrenDictionary
  );

  const updatedGoodsItems = setAmountOrRemoveItem(
    tempArray,
    selectedProduct,
    Number(amount)
  );

  const order = {
    orderId: this.id,
    orderType: this.current.orderType,
    transactionId: uuidv4(),
    GoodsItems: deleteTypesFromGoodsItems(updatedGoodsItems),
    eTag: new Date().toJSON(),
  };

  try {
    const {data} = await updateGoodsItems(order);

    if (data.success) {
      refreshCSO();
    } else {
      notifyWarning('Something went wrong, please contact IT');
    }
  } catch (error) {
    notifyError('Error while deleting item: ', error);
  }
}

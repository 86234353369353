import React, {useEffect, useState} from 'react';
import {FaPen, FaTrash} from 'react-icons/fa';
import {Button, Col, Row} from 'react-bootstrap';

import {
  ProductGroupWrapper,
  ProductWrapper,
} from '../../../shared/product/product-row-components';
import {ProductTitle} from '../../../shared/product/ProductTitle';
import {TotalSellPrice} from '../../../shared/product/TotalSellPrice';
import {TotalCostPrice} from '../../../shared/product/TotalCostPrice';
import {TotalPriceDifference} from '../../../shared/product/TotalPriceDifference';
import {BaseModal} from '../../../shared/modals/BaseModal';
import {productTypes} from '../../../../utils/determineItemTypeOnPimProduct';

import ProductOptionRow from './ProductOptionRow';
import AssignableProductRow from './AssignableProductRow';
import csoManager from '../../../../utils/csoManager/csoManager';

export const KitItemRow = ({childrenDictionary, item, orderLinesObject, level}) => {
  const [showModal, setShowModal] = useState({isVisible: false, type: null});
  const childIds = childrenDictionary[item.orderlineId];

  let costPriceSumChildren = 0;

  if (childIds) {
    costPriceSumChildren = childIds.reduce(
      (a, b) => a + orderLinesObject[b].selectedSupplier?.costPrice.value,
      0
    );
  }

  useEffect(() => {
    return () => setShowModal({isVisible: false, type: null});
  }, []);

  return (
    <>
      <ProductGroupWrapper>
        <ProductWrapper level={level}>
          <Row>
            <Col style={{paddingLeft: '25px'}}>
              <ProductTitle id={item.item.id} externalIds={item.item.externalIds}>
                <strong>{item.orderedQuantity.value}x</strong>
                <span> {item.item.name}</span>
              </ProductTitle>
            </Col>
            <Col md={3}>
              <TotalSellPrice amount={item.quantity.value} item={item} />

              <TotalCostPrice item={item} sumOfChildren={costPriceSumChildren} />

              <TotalPriceDifference
                item={item}
                costPriceSumChildren={costPriceSumChildren}
              />

              <div className="mt-2 d-flex justify-content-end">
                {item.allowedActions.isRemovable && (
                  <Button
                    disabled={csoManager?.current?.hasNetSuiteId}
                    className="ml-2 d-flex align-items-center"
                    size="sm"
                    variant="outline-danger"
                    onClick={() => setShowModal({isVisible: true, type: 'delete'})}
                  >
                    <FaTrash title="Remove product" size={16} />
                  </Button>
                )}
                {item.allowedActions.isMutable && (
                  <Button
                    disabled={csoManager?.current?.hasNetSuiteId}
                    className="ml-2 d-flex align-items-center"
                    size="sm"
                    variant="outline-secondary"
                    onClick={() => setShowModal({isVisible: true, type: 'update'})}
                  >
                    <FaPen title="Edit price" size={16} />
                  </Button>
                )}
              </div>
            </Col>
          </Row>
        </ProductWrapper>

        {childrenDictionary[item.orderlineId]
          ?.filter((el) => !orderLinesObject[el].fullyAssigned)
          .map((el, index) => {
            if (orderLinesObject[el].item.itemType === productTypes.optionItem) {
              return (
                <ProductOptionRow
                  key={index}
                  item={orderLinesObject[el]}
                  orderLinesObject={orderLinesObject}
                  childrenDictionary={childrenDictionary}
                />
              );
            } else if (
              orderLinesObject[el].item.itemType === productTypes.inventoryItem
            ) {
              return (
                <AssignableProductRow
                  key={index}
                  item={orderLinesObject[el]}
                  level={level + 1}
                />
              );
            }

            return null;
          })}
      </ProductGroupWrapper>

      <BaseModal
        isVisible={showModal?.isVisible}
        type={showModal.type}
        selectedProduct={item}
        option={false}
        onClose={() => setShowModal({isVisible: false, type: null})}
      />
    </>
  );
};

import store from '../../../redux/store';
import {postSellerComment} from '../../../apis/ApiCalls';
import {addNewComment} from '../../../redux/actions/actions';
import {notifyError, notifyWarning} from '../../toasts';

/**
 * Sellercomments are internal comments by Gadero employees
 * @param {string} newComment - The comment from the seller (Gadero)
 * @returns {Promise<void>}
 */
export async function addSellerComment(newComment) {
  const author = store.getState().oidc.user.profile.name;

  const postObject = {
    author: author,
    orderId: this.id,
    orderType: 'SalesOrder',
    eTag: new Date().toJSON(),
    comment: newComment,
  };

  try {
    const {data} = await postSellerComment(postObject);

    if (data.success) {
      store.dispatch(
        addNewComment({
          author: author,
          comment: newComment,
          created: new Date().toJSON(),
        })
      );
    } else {
      notifyWarning('Comment toevoegen is mislukt, neem contact op met IT');
    }
  } catch (error) {
    notifyError('Er is iets misgegaan, neem bij herhaling contact op met IT');
  }
}

import {Col, Form, FormControl, InputGroup, Row} from 'react-bootstrap';
import React from 'react';

import {IAddress} from '../../../../apis/postApis/postApis.types/setShippingInfoPostObject';
import {inputStyles} from './DeliveredForm';

interface Props {
  localPickUpLocationAddress?: IAddress;
}

export const LocalPickUpForm = ({localPickUpLocationAddress}: Props) => {
  return (
    <>
      <label>Pickup address:</label>
      <InputGroup className="mb-2">
        <InputGroup.Text style={inputStyles}>Street</InputGroup.Text>
        <Form.Control
          type="text"
          placeholder={localPickUpLocationAddress?.street}
          value={localPickUpLocationAddress?.street || ''}
          disabled
        />
      </InputGroup>
      <Row>
        <Col>
          <InputGroup className="mb-2">
            <InputGroup.Text style={inputStyles}>House number</InputGroup.Text>
            <Form.Control
              type="text"
              placeholder={localPickUpLocationAddress?.number}
              disabled
              value={localPickUpLocationAddress?.number || ''}
            />
          </InputGroup>
        </Col>
        <Col className="col-5">
          <InputGroup className="mb-2">
            <InputGroup.Text style={inputStyles}>Number addition</InputGroup.Text>
            <FormControl
              type="text"
              placeholder={localPickUpLocationAddress?.numberAddition}
              value={localPickUpLocationAddress?.numberAddition || ''}
              disabled
            />
          </InputGroup>
        </Col>
      </Row>
      <InputGroup className="mb-2">
        <InputGroup.Text style={inputStyles}>Zip</InputGroup.Text>
        <Form.Control
          type="text"
          placeholder={localPickUpLocationAddress?.zip}
          disabled
          value={localPickUpLocationAddress?.zip || ''}
        />
      </InputGroup>
      <InputGroup className="mb-2">
        <InputGroup.Text style={inputStyles}>City</InputGroup.Text>
        <Form.Control
          type="text"
          placeholder={localPickUpLocationAddress?.place}
          value={localPickUpLocationAddress?.place || ''}
          disabled
        />
      </InputGroup>
      <InputGroup className="mb-2">
        <InputGroup.Text style={inputStyles}>Country</InputGroup.Text>
        <Form.Control
          type="text"
          placeholder={localPickUpLocationAddress?.country}
          value={localPickUpLocationAddress?.country || ''}
          disabled
        />
      </InputGroup>
    </>
  );
};

import { APP_LOADING } from '../actions/actionTypes';

const initialState = { isLoading: true };

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case APP_LOADING:
      const newState = { ...state };
      newState.isLoading = action.payload;

      return newState;

    default:
      return state;
  }
};

export default appReducer;

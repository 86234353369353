import {Card, Col, Row} from 'react-bootstrap';
import React from 'react';
import RSelect from 'react-select';

import SearchBar from '../../shared/form/SearchBar/SearchBar';

const Filters = ({defaultFilters, currentFilters, onChange}) => {
  return (
    <Card className="mb-2">
      <Card.Body>
        <Card.Title>Filters</Card.Title>

        <Row>
          <Col lg={12} className="mb-4">
            <SearchBar
              onChange={(query) => onChange(query, 'query')}
              value={currentFilters.query ?? ''}
              placeholder="Search for order-id, customer, address or comment"
            />
          </Col>
        </Row>
        <Row className="mb-4">
          <Col lg={6}>
            <p className="h6">Orderstatus</p>
            <RSelect
              onChange={(e) => onChange(e.value, 'status')}
              placeholder="Select an orderstatus"
              value={defaultFilters.status.find(
                (item) => item.value === currentFilters?.status
              )}
              options={defaultFilters.status}
            />
          </Col>
          <Col lg={6}>
            <p className="h6">Shipping method</p>
            <RSelect
              placeholder="Select a shipping method"
              onChange={(e) => onChange(e.value, 'shippingMethod')}
              value={defaultFilters.shippingMethod.find(
                (item) => item.value === currentFilters?.shippingMethod
              )}
              options={defaultFilters.shippingMethod}
            />
          </Col>
        </Row>
        <Row className="mb-4">
          <Col lg={6}>
            <p className="h6">Country</p>
            <RSelect
              placeholder="Select a country"
              onChange={(e) => onChange(e.value, 'country')}
              value={defaultFilters.country.find(
                (item) => item.value === currentFilters?.country
              )}
              options={defaultFilters.country}
            />
          </Col>
          <Col lg={6}>
            <p className="h6">Region</p>
            <RSelect
              placeholder="Select a region"
              onChange={(e) => onChange(e.value, 'region')}
              value={defaultFilters.region.find(
                (item) => item.value === currentFilters?.region
              )}
              options={defaultFilters.region}
            />
          </Col>
        </Row>
        <Row className="mb-4">
          <Col lg={6}>
            <p className="h6">Supplier</p>
            <RSelect
              placeholder="Select a supplier"
              onChange={(e) => onChange(e?.value, 'supplierId')}
              value={defaultFilters.supplier.find(
                (item) => item?.value === currentFilters?.supplier
              )}
              options={defaultFilters.supplier}
            />
          </Col>
          <Col>
            <p className="h6">Promised delivery day</p>
            <RSelect
              onChange={(e) => onChange(e.value, 'deadlineFilterType')}
              placeholder="Select..."
              value={defaultFilters.status.find(
                (item) => item.value === currentFilters?.value
              )}
              options={defaultFilters.deadlineFilterType}
            />
          </Col>
        </Row>
        <Row>
          <Col lg={6}>
            <p className="h6">NetSuite sync</p>
            <RSelect
              placeholder="Selecteer sync status"
              onChange={(e) => onChange(e?.value, 'inNetSuite')}
              value={defaultFilters.inNetSuite.find(
                (item) => item?.value === currentFilters?.inNetSuite
              )}
              options={defaultFilters.inNetSuite}
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default Filters;

import styled from 'styled-components';

export interface StackProps {
  gap?: string;
  borderWidth?: string;
  borderColor?: string;
}

export const Stack = styled.div<StackProps>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  > * {
    margin-block: 0;
  }

  > * + * {
    margin-block-start: ${props => props.gap || 'var(--s1)'};
    ${props =>
      props.borderWidth &&
      `border-block-start: ${props.borderWidth} solid ${props.borderColor || 'var(--color-dark)'};`}
  }
`;

export default Stack;

import { applyMiddleware, createStore } from 'redux';
import { loadUser } from 'redux-oidc';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

import userManager from '../utils/userManager';
import loggerMiddleware from './middlewares/loggerMiddleware';
import axiosHeaderMiddleWare from './middlewares/axiosMiddleware';

import rootReducer from './reducers';

const middleware = [axiosHeaderMiddleWare, loggerMiddleware, thunk];

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(...middleware))
);

loadUser(store, userManager);

export default store;

import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useParams} from 'react-router-dom';
import qs from 'qs';
import {Col, Container, Row} from 'react-bootstrap';

import csoManager from '../../../utils/csoManager/csoManager';
import LoadingSpinner from '../../shared/LoadingSpinner';
import ErrorAndGoBack from './ErrorAndGoBack';
import {PageFooter} from './PageFooter';
import {initializeState} from '../../../utils/initializeState';
import {ProductContainer} from '../../shared/product/ProductContainer';
import {clearCSO, setLoading, setOrderNotFound} from '../../../redux/actions/actions';

import {PageHeader} from './OrderDetails/PageHeader';
import {PurchaseOrders} from './PurchaseOrders/PurchaseOrders';
import AssignedProductsDisplay from './AssignedProducts/AssignedProductsDisplay';

const ClientSalesOrderEdit = ({location}) => {
  const {isLoading} = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const history = useHistory();
  const {orderId} = useParams();

  useEffect(() => {
    const {query} = location;

    /**
     * The silent refresh replaces the previous path.
     * is to ensure we can go back to home with the selected filters
     */
    localStorage['opa-filters'] = qs.stringify(query, {skipNulls: true});

    csoManager.setId(orderId);

    const setupOrder = async () => {
      const {data} = await csoManager.getDetail();

      if (!data) {
        dispatch(setOrderNotFound());
        dispatch(setLoading(false));
      } else {
        initializeState(data);
      }
    };

    setupOrder();

    return () => {
      dispatch(clearCSO());
    };
  }, [dispatch, location, orderId]);

  const orderLinesObject = useSelector((state) => state.orderLines);
  const childrenDictionary = useSelector((state) => state.childrenDictionary);
  const purchaseOrders = useSelector((state) => state.purchaseOrders);
  const salesOrder = useSelector((state) => state.cso);

  // Loading data
  if (isLoading) {
    return <LoadingSpinner inline={false} loading={isLoading} />;
  }

  // Data is loaded, but no order was found
  if (!isLoading && !salesOrder.orderFound) {
    return <ErrorAndGoBack onClick={() => history.goBack()} message="Order not found" />;
  }

  //Data is loaded and order is found
  if (!isLoading && salesOrder.orderFound) {
    return (
      <Container className="pb-4" fluid>
        <PageHeader orderId={orderId} salesOrder={salesOrder} />
        <Row>
          <Col>
            <ProductContainer
              childrenDictionary={childrenDictionary}
              orderLinesObject={orderLinesObject}
              salesOrder={salesOrder}
            />
            <AssignedProductsDisplay
              orderLinesObject={orderLinesObject}
              purchaseOrders={purchaseOrders}
            />
          </Col>
          <Col md={5}>
            <PurchaseOrders purchaseOrders={purchaseOrders} />
          </Col>
        </Row>

        <PageFooter salesOrderGoodsItems={orderLinesObject} />
      </Container>
    );
  }

  return null;
};
export default ClientSalesOrderEdit;

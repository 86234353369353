import axios, {AxiosResponse} from 'axios';
import jwt_decode from 'jwt-decode';

import {IChangeBuyerInfoPostObject} from './postApis.types/changeBuyerInfoPostObject.type';
import {IChangeReceiverInfoPostObject} from './postApis.types/changeReceiverInfoPostObject.type';
import {IPostApiResponse} from './postApis.types/postApiResponse.type';
import {ISetShippingInfoPostObject} from './postApis.types/setShippingInfoPostObject';

const ORDERS_API_URL = process.env.REACT_APP_orders_api_url;

const upgradeDataWithUserIdFromHeader = (
  data:
    | IChangeBuyerInfoPostObject
    | IChangeReceiverInfoPostObject
    | ISetShippingInfoPostObject
) => {
  const authHeader = axios.defaults.headers.common['Authorization'];

  if (authHeader) {
    //add user id to the data
    if (typeof authHeader === 'string') {
      const decoded: {sub: string} = jwt_decode(authHeader.substr(7));
      data.userId = decoded.sub;
    } //remove "bearer "

    return;
  }

  return;
};

export const updateBuyerInfo = (
  changeBuyerInfoPostObject: IChangeBuyerInfoPostObject
): Promise<AxiosResponse<IPostApiResponse>> => {
  upgradeDataWithUserIdFromHeader(changeBuyerInfoPostObject);

  return axios.post(`${ORDERS_API_URL}salesorder/changeBuyer`, changeBuyerInfoPostObject);
};

export const updateReceiverInfo = (
  changeReceiverInfoPostObject: IChangeReceiverInfoPostObject
): Promise<AxiosResponse<IPostApiResponse>> => {
  upgradeDataWithUserIdFromHeader(changeReceiverInfoPostObject);

  return axios.post(
    `${ORDERS_API_URL}salesorder/changeReceiver`,
    changeReceiverInfoPostObject
  );
};

export const changeShippingMethod = (
  setShippingInfoPostObject: ISetShippingInfoPostObject
): Promise<AxiosResponse<IPostApiResponse>> => {
  upgradeDataWithUserIdFromHeader(setShippingInfoPostObject);

  return axios.post(`${ORDERS_API_URL}salesorder/setShipping`, setShippingInfoPostObject);
};

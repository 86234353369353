import {v4 as uuidv4} from 'uuid';

import {transformPIMProductToGoodsItem} from '../../orderLines/transformPIMProductToGoodsItem';
import {goodsItemDecorator} from '../../orderLines/addPropsToGoodsItems';
import {getSubItemsFromPIM} from '../../orderLines/getSubItemsFromPIM';
import {
  rebuildSalesOrderGoodsItems,
  transformSalesOrderGoodsItemsToOrderLines,
} from '../../orderLines/transformSalesOrderGoodsItemsToOrderLines';
import {mergeOrderlines} from '../../orderLines/mergeOrderLines';
import store from '../../../redux/store';
import {buildChildrenDictionary} from '../../dictionary/buildChildrenDictionary';
import {deleteTypesFromGoodsItems} from '../../orderLines/deleteTypesFromGoodsItems';
import {updateGoodsItems} from '../../../apis/ApiCalls';
import {notifyError, notifyWarning} from '../../toasts';
import {refreshCSO} from '../refreshCSO';

/**
 * This function takes a kitItem from PIM, converts it into the CSO combikitItem format and adds it to the CSO
 * @param {object} kitItem The kitItem that will be added to the CSO
 * @param {number} amount The amount of kitItems that will be added
 * @returns {Promise<void>}
 */
export async function addKitItemToSalesOrder(kitItem, amount) {
  const newGoodsItem = transformPIMProductToGoodsItem(kitItem, amount);

  const newProductWithProps = goodsItemDecorator(newGoodsItem);

  newProductWithProps.item.subItems = await getSubItemsFromPIM(
    kitItem.subProducts,
    newProductWithProps
  );

  const itemOrderline = transformSalesOrderGoodsItemsToOrderLines([newProductWithProps]);

  const newOrderlines = mergeOrderlines(store.getState().orderLines, itemOrderline);

  const newChildrenDictionary = buildChildrenDictionary(Object.values(newOrderlines));

  const newOriginalGoodsItems = rebuildSalesOrderGoodsItems(
    newOrderlines,
    newChildrenDictionary
  );

  let postObject = {
    orderId: this.id,
    transactionId: uuidv4(),
    orderType: 'salesOrder',
    goodsItems: deleteTypesFromGoodsItems(newOriginalGoodsItems),
  };

  try {
    const {data} = await updateGoodsItems(postObject);

    if (data.success) {
      refreshCSO();
    } else {
      notifyWarning('Toevoegen is mislukt, neem contact op met IT');
    }
  } catch (error) {
    notifyError('Er is iets misgegaan, neem bij herhaling contact op met IT', error);
  }
}

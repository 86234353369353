import React, {useState} from 'react';
import {Modal, Button, Form} from 'react-bootstrap';

import csoManager from '../../../utils/csoManager/csoManager';

const SellerCommentsModal = ({handleClose}) => {
  const [newComment, setNewComment] = useState('');

  const handlePostComment = () => {
    csoManager.addSellerComment(newComment);
    handleClose();
  };

  return (
    <>
      <Modal.Header>
        <Modal.Title>Add comment</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
          <Form.Control
            as="textarea"
            rows={3}
            onChange={(e) => setNewComment(e.target.value)}
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => handlePostComment()}>Opslaan</Button>
        <Button variant="danger" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </>
  );
};

export default SellerCommentsModal;

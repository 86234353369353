import React from 'react';
import {Modal, Button} from 'react-bootstrap';
import styled from 'styled-components';

import {paymentTypes} from '../../../utils/data/paymentTypes';
import {StickyModalFooter} from './StickyModalFooter';

const MyRow = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  background-color: ${(props) => (props.index % 2 === 0 ? 'lightgrey' : 'none')};
  padding: 0.25rem;
`;

const MyCol = styled.div`
  display: flex;
  justify-content: center;
`;

const paymentNames = [
  {label: 'Discount', value: paymentTypes.discount},
  {label: 'Payment', value: paymentTypes.payment},
  {label: 'Refund', value: paymentTypes.refund},
];

const PaymentDetailsModal = ({handleClose, paymentDetails}) => {
  const calculateAmountPaid = (payment) => {
    if (payment.$type === paymentTypes.payment) {
      return payment.amountPaid.value;
    } else if ([paymentTypes.discount, paymentTypes.refund].includes(payment.$type)) {
      return payment?.amountPaid?.value * -1;
    }
  };

  return (
    <>
      <Modal.Header>
        <Modal.Title>Payment details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <MyRow style={{marginBottom: '0.5rem'}}>
          <MyCol>
            <strong>Payment type</strong>
          </MyCol>
          <MyCol>
            <strong>Reference</strong>
          </MyCol>
          <MyCol>
            <strong>Payment method</strong>
          </MyCol>
          <MyCol>
            <strong>Payment costs</strong>
          </MyCol>
          <MyCol>
            <strong>Payment</strong>
          </MyCol>
        </MyRow>
        {paymentDetails.map((payment, i) => (
          <MyRow index={i} key={i}>
            <MyCol>
              {paymentNames.find((name) => name.value === payment.$type).label}
            </MyCol>
            <MyCol>{payment.paymentReference}</MyCol>
            <MyCol>{payment.paymentMethod}</MyCol>
            <MyCol>{payment.paymentMethodCost?.value}</MyCol>
            <MyCol>{calculateAmountPaid(payment)}</MyCol>
          </MyRow>
        ))}
      </Modal.Body>
      <StickyModalFooter>
        <Button variant="danger" onClick={handleClose}>
          Close
        </Button>
      </StickyModalFooter>
    </>
  );
};

export default PaymentDetailsModal;

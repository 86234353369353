import React from 'react';
import {Modal} from 'react-bootstrap';
import {any, oneOf, bool, func} from 'prop-types';

import ChangeProductModal from './ChangeProductModal';
import DeleteProductModal from './DeleteProductModal';
import ProductFinderModal from './ProductFinderModal';
import SellerCommentsModal from './SellerCommentsModal';
import PaymentDetailsModal from './PaymentDetailsModal';
import OrderLogModal from './OrderLogModal';
import ChangeShippingModal from './change-shipping-modal/ChangeShippingModal';
import SoapiLogicModal from './soapi-logic-modal/SoapiLogicModal';

export const BaseModal = ({
  type,
  isVisible,
  onClose,
  selectedProduct,
  option = false,
  paymentDetails,
  orderLog,
  orderId,
  salesOrder,
  shippingMethod,
  pickupLocation,
}) => {
  return (
    <Modal
      /* Turning animation to true will give a smoother user experience,
          but in development this will lead to warnings since findDOMnode
          is deprecated in strictmode */
      animation={false}
      backdrop="static"
      show={isVisible}
      onHide={onClose}
      size="lg"
      centered
    >
      {type === 'update' && (
        <ChangeProductModal
          title={`Changes to ${selectedProduct?.item.name}`}
          item={selectedProduct}
          handleClose={onClose}
        />
      )}

      {type === 'delete' && (
        <DeleteProductModal
          title={`Delete ${selectedProduct?.item.name}?`}
          item={selectedProduct}
          handleClose={onClose}
        />
      )}

      {type === 'find' && (
        <ProductFinderModal
          option={option}
          item={selectedProduct}
          handleClose={onClose}
        />
      )}

      {type === 'add-seller-comment' && <SellerCommentsModal handleClose={onClose} />}

      {type === 'payment-details' && (
        <PaymentDetailsModal paymentDetails={paymentDetails} handleClose={onClose} />
      )}

      {type === 'soapi' && (
        <SoapiLogicModal
          handleClose={onClose}
          salesOrderShippingMethod={shippingMethod}
          pickupLocation={pickupLocation}
        />
      )}

      {type === 'orderLog' && <OrderLogModal orderLog={orderLog} handleClose={onClose} />}

      {type === 'change-shipping' && (
        <ChangeShippingModal
          handleClose={onClose}
          orderId={orderId}
          salesOrder={salesOrder}
        />
      )}
    </Modal>
  );
};

BaseModal.propTypes = {
  type: oneOf([
    'update',
    'delete',
    'find',
    'add-seller-comment',
    'payment-details',
    'orderLog',
    'change-shipping',
    'soapi',
    '',
    null,
  ]),
  isVisible: bool,
  onClose: func,
  selectedProduct: any, // gadero item
  option: bool,
};

import {Button, Col, Form, Row} from 'react-bootstrap';
import Select from 'react-select';
import {countryOptions} from '../../../../shared/modals/change-shipping-modal/country-config';
import {updateBuyerInfo, updateReceiverInfo} from '../../../../../apis/postApis/postApis';
import {notifyError, notifySuccess} from '../../../../../utils/toasts';
import {useReducer} from 'react';
import {
  customerInformationReducer,
  SET_CHAMBER_OF_COMMERCE_NUMBER,
  SET_CONTACT_NAME,
  SET_COUNTRY_CODE,
  SET_EMAIL,
  SET_NAME,
  SET_NUMBER,
  SET_NUMBER_ADDITION,
  SET_PHONE,
  SET_PLACE,
  SET_REFERENCE,
  SET_STREET,
  SET_VAT_NUMBER,
  SET_ZIP,
} from './utils/customer-information-reducer';
import {postObjectBuilder} from './utils/post-object-builder';
import {useParams} from 'react-router-dom';
import {refreshCSO} from '../../../../../utils/csoManager/refreshCSO';

export const EditCustomerInformation = ({customerData, isBuyer}) => {
  const {orderId} = useParams();

  const initialState = {
    name: customerData.contact?.name ?? '',
    contactName: customerData.contact?.contactName ?? '',
    phone: customerData.contact?.phone ?? '',
    email: customerData.contact?.email ?? '',
    street: customerData.address?.street ?? '',
    number: customerData.address?.number ?? '',
    numberAddition: customerData.address?.numberAddition ?? '',
    place: customerData.address?.place ?? '',
    zip: customerData.address?.zip ?? '',
    countryCode: customerData.address?.countryCode?.toUpperCase() ?? '',
    chamberOfCommerceNumber: customerData.chamberOfCommerceNumber ?? '',
    vatNumber: customerData.vatNumber ?? '',
    reference: customerData.reference ?? '',
  };

  const [state, dispatch] = useReducer(
    customerInformationReducer,
    initialState,
    undefined
  );

  const changeBuyerInfo = async () => {
    const postObject = postObjectBuilder(
      state,
      orderId,
      isBuyer,
      customerData.address.latLong
    );

    try {
      const response = await updateBuyerInfo(postObject);

      if (response.data.success) {
        notifySuccess('Data successfully edited.');
        refreshCSO();
      } else {
        notifyError('Saving the data has failed, please contact IT.');
      }
    } catch (error) {
      notifyError('Something went wrong, please contact IT.');
    }
  };

  const changeReceiverInfo = async () => {
    const postObject = postObjectBuilder(
      state,
      orderId,
      isBuyer,
      customerData.address.latLong
    );

    try {
      const response = await updateReceiverInfo(postObject);

      if (response.data.success) {
        notifySuccess('Update successful');
        refreshCSO();
      } else {
        notifyError('Saving the data has failed, please contact IT.');
      }
    } catch (error) {
      notifyError('Something went wrong, please contact IT.');
    }
  };

  return (
    <>
      {customerData.contact?.name && (
        <Row>
          <Col>
            <Form.Control
              size="sm"
              className="mb-2"
              type="text"
              placeholder={'Company name'}
              value={state.name}
              onChange={(e) => dispatch({type: SET_NAME, payload: e.target.value})}
            />
          </Col>
        </Row>
      )}
      <Row>
        <Col style={{height: '500px'}}>
          <Form.Control
            size="sm"
            className="mb-2"
            type="text"
            placeholder={'Full name'}
            value={state.contactName}
            onChange={(e) => dispatch({type: SET_CONTACT_NAME, payload: e.target.value})}
          />
          <Form.Control
            size="sm"
            className="mb-2"
            type="text"
            placeholder={'Street'}
            value={state.street}
            onChange={(e) => dispatch({type: SET_STREET, payload: e.target.value})}
          />
          <Row>
            <Col>
              <Form.Control
                size="sm"
                className="mb-2"
                type="text"
                placeholder={'House number'}
                value={state.number}
                onChange={(e) => dispatch({type: SET_NUMBER, payload: e.target.value})}
              />
            </Col>
            <Col>
              <Form.Control
                size="sm"
                className="mb-2"
                type="text"
                placeholder={'Number addition'}
                value={state.numberAddition}
                onChange={(e) =>
                  dispatch({type: SET_NUMBER_ADDITION, payload: e.target.value})
                }
              />
            </Col>
          </Row>
          <Form.Control
            size="sm"
            className="mb-2"
            type="text"
            placeholder={'Zip'}
            value={state.zip}
            onChange={(e) => dispatch({type: SET_ZIP, payload: e.target.value})}
          />
          <Form.Control
            size="sm"
            className="mb-2"
            type="text"
            placeholder={'City'}
            value={state.place}
            onChange={(e) => dispatch({type: SET_PLACE, payload: e.target.value})}
          />
          <Form.Control
            size="sm"
            className="mb-2"
            type="text"
            placeholder={'Phone number'}
            value={state.phone}
            onChange={(e) => dispatch({type: SET_PHONE, payload: e.target.value})}
          />
          <Form.Control
            size="sm"
            className="mb-2"
            type="email"
            placeholder={'email@email.nl'}
            value={state.email}
            onChange={(e) => dispatch({type: SET_EMAIL, payload: e.target.value})}
          />
          <Select
            placeholder={'Select country'}
            isClearable
            value={countryOptions.filter(function (option) {
              return option.value === state.countryCode;
            })}
            options={countryOptions}
            onChange={(e) =>
              dispatch({type: SET_COUNTRY_CODE, payload: e ? e.value : ''})
            }
          />
        </Col>
      </Row>

      {isBuyer && customerData.contact?.name && (
        <>
          <hr />
          <Row>
            <Col>Chamber of Commerce number:</Col>
            <Col>
              <Form.Control
                size="sm"
                className="mb-2"
                type="text"
                placeholder={'Chamber of Commerce number'}
                value={state.chamberOfCommerceNumber}
                onChange={(e) =>
                  dispatch({
                    type: SET_CHAMBER_OF_COMMERCE_NUMBER,
                    payload: e.target.value,
                  })
                }
              />
            </Col>
          </Row>
          <Row>
            <Col>VAT number:</Col>
            <Col>
              <Form.Control
                size="sm"
                className="mb-2"
                type="text"
                placeholder={'VAT number'}
                value={state.vatNumber}
                onChange={(e) =>
                  dispatch({type: SET_VAT_NUMBER, payload: e.target.value})
                }
              />
            </Col>
          </Row>
          <Row>
            <Col>Reference:</Col>
            <Col>
              <Form.Control
                size="sm"
                className="mb-2"
                type="text"
                placeholder={'Reference'}
                value={state.reference}
                onChange={(e) => dispatch({type: SET_REFERENCE, payload: e.target.value})}
              />
            </Col>
          </Row>
        </>
      )}

      <Button
        type="submit"
        className="btn btn-sm btn-success float-right"
        onClick={isBuyer ? changeBuyerInfo : changeReceiverInfo}
        title="Submit changes"
      >
        Save
      </Button>
    </>
  );
};

import React from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import 'dayjs/locale/nl';

const CommentWrapper = styled.div`
  border-bottom: 1px solid #e3e3e3;

  white-space: pre-line;
  vertical-align: bottom;
`;

const Comment = ({comment}) => {
  const dateCreated = dayjs(comment.created).format(' DD-MM HH:mm');

  return (
    <CommentWrapper>
      <em>{comment.author}</em>
      <strong>{' - '}</strong>
      <em>{dateCreated}</em>
      <strong>{' : '}</strong>
      {comment.comment}
    </CommentWrapper>
  );
};

export default Comment;

import React, {useRef} from 'react';
import {Modal, Button, Row, Col, InputGroup, FormControl} from 'react-bootstrap';

import csoManager from '../../../utils/csoManager/csoManager';

const DeleteProductModal = ({handleClose, item, title}) => {
  const amount = useRef(null);

  const handleRemoveProduct = () => {
    csoManager.removeProductFromSalesOrder(item, amount?.current?.value);
  };

  return (
    <>
      <Modal.Header>
        <Modal.Title>{title ? title : 'Delete product?'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md={6}>
            <label>
              <span>Amount</span>
              <InputGroup className="mb-3">
                <FormControl
                  placeholder="0"
                  aria-label="Amount"
                  ref={amount}
                  type="number"
                  min={0}
                  max={item?.quantity?.value}
                  defaultValue={item?.quantity?.value ?? 0}
                />
              </InputGroup>
            </label>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-danger" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="danger" onClick={() => handleRemoveProduct()}>
          Yes, delete
        </Button>
      </Modal.Footer>
    </>
  );
};

export default DeleteProductModal;

import {transformInventoryItemWithoutChildrenIntoKitItem} from './unAssignedGoodsItemsFunctions/transformInventoryItemWithoutChildrenIntoKitItem';
import {setOrderStatus} from './csoDetailsFunctions/setOrderStatus';
import {setDeadline} from './csoDetailsFunctions/setDeadLine';
import {addSellerComment} from './csoDetailsFunctions/addSellerComment';
import {removeProductFromSalesOrder} from './unAssignedGoodsItemsFunctions/removeProductFromSalesOrder';
import {addProductOptionToSalesOrder} from './unAssignedGoodsItemsFunctions/addProductOptionToSalesOrder';
import {addKitItemToSalesOrder} from './unAssignedGoodsItemsFunctions/add-kit-item-to-sales-order';
import {addInventoryItemToSalesOrder} from './unAssignedGoodsItemsFunctions/add-inventory-item-to-sales-order';
import {updatePriceAmountItem} from './unAssignedGoodsItemsFunctions/updatePriceAmountItem';
import {getDetail} from './csoDetailsFunctions/getDetail';
import {setId} from './csoDetailsFunctions/setId';

const CSOManager = {
  id: null,
  current: null,

  setId: setId,

  getDetail: getDetail,

  updatePriceAmountItem: updatePriceAmountItem,

  addInventoryItemToSalesOrder: addInventoryItemToSalesOrder,

  addKitItemToSalesOrder: addKitItemToSalesOrder,

  addProductOptionToSalesOrder: addProductOptionToSalesOrder,

  removeProductFromSalesOrder: removeProductFromSalesOrder,

  addSellerComment: addSellerComment,

  setDeadline: setDeadline,

  setOrderStatus: setOrderStatus,

  transformInventoryItemWithoutChildrenIntoKitItem:
    transformInventoryItemWithoutChildrenIntoKitItem,
};

export default CSOManager;

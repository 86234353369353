import {LOAD_PURCHASEORDERS} from '../actions/actionTypes';

const initialState = [];

const purchaseOrdersReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_PURCHASEORDERS:
      return [...action.payload];

    default:
      return state;
  }
};
export default purchaseOrdersReducer;

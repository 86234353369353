import {ADD_TO_CHILDRENDICTIONARY, LOAD_CHILDRENDICTIONARY} from '../actions/actionTypes';
import {deepCopy} from '../../utils/deepCopy';

const initialState = null;

const childrenDictionaryReducer = (state = initialState, action) => {
  let copyState = deepCopy(state);

  switch (action.type) {
    case LOAD_CHILDRENDICTIONARY:
      copyState = {...action.payload};

      return copyState;

    case ADD_TO_CHILDRENDICTIONARY:
      copyState = {...copyState, id: []};

      return copyState;

    default:
      return state;
  }
};

export default childrenDictionaryReducer;

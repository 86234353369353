import {initializeState} from '../initializeState';

import csoManager from './csoManager';

export const refreshCSO = async () => {
  await new Promise((resolve) => {
    setTimeout(resolve, 200);
  });

  const {data} = await csoManager.getDetail();

  if (data) {
    initializeState(data);
  }
};

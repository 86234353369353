import {LOAD_ORDERLINES} from '../actions/actionTypes';
import {deepCopy} from '../../utils/deepCopy';

const initialState = {};

const orderLinesReducer = (state = initialState, action) => {
  let copyState = deepCopy(state);

  switch (action.type) {
    case LOAD_ORDERLINES:
      copyState = {...action.payload};

      return copyState;

    default:
      return state;
  }
};
export default orderLinesReducer;

import React, { useState } from 'react';
import { Button, FormControl } from 'react-bootstrap';

const ProductFinderInput = ({ handleSubmitProduct, product }) => {
  const [amount, setAmount] = useState('');

  return (
    <div className="d-flex justify-content-between">
      <FormControl
        size="sm"
        type="number"
        value={amount}
        min={0}
        onChange={(e) => setAmount(e.target.value)}
      />
      <Button
        className="ml-2"
        size="sm"
        onClick={() => handleSubmitProduct(product, amount)}
      >
        Add
      </Button>
    </div>
  );
};

export default ProductFinderInput;

import React from 'react';
import { any, string } from 'prop-types';

export const LinkButton = ({ href, children }) => {
  return (
    <a target="_blank" rel="noreferrer" href={href} type="button">
      {children}
    </a>
  );
};

LinkButton.propTypes = {
  href: string.isRequired,
  children: any,
};

import React, {useMemo, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {Col, Row, Button, Dropdown, DropdownButton} from 'react-bootstrap';
import {SiGooglemessages} from 'react-icons/si';
import {IoArrowBack} from 'react-icons/io5';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {FaBook, FaEdit, FaExclamation} from 'react-icons/fa';
import debounce from 'lodash.debounce';

import {convertDataIntoPaymentsObject} from '../../../../utils/convertDataIntoPaymentsObject';
import csoManager from '../../../../utils/csoManager/csoManager';
import {getCSOShippingMethod} from '../../../../utils/delivery/deliveryFunctions';
import {salesOrderStatusOptions} from '../../../../utils/data/salesOrderStatusOptions';
import Comment from './Comment';
import {BaseModal} from '../../../shared/modals/BaseModal';
import {OrderDetails} from './OrderDetails';

const NETSUITE_ORDERS_URL = process.env.REACT_APP_netsuite_orders_url;

export const PageHeader = ({salesOrder, orderId}) => {
  const [showDetail, toggleShowDetail] = useState(false);
  const [showModal, setShowModal] = useState({isVisible: false, type: ''});
  const history = useHistory();
  const internalOrderNumber = salesOrder.buyerReference?.includes('zilver')
    ? salesOrder.buyerReference.split('/')[3]
    : salesOrder.buyerReference;
  const {sellerComments: comments, deadLine} = salesOrder;

  const shippingMethod = salesOrder?.shipping?.shippingMethod;
  const pickupLocation =
    shippingMethod === 'LocalPickup'
      ? salesOrder?.shipping?.deliveryAddress?.place
      : null;

  const {
    totalDiscount,
    totalRefunds,
    totalPayments,
    paymentMethodCost,
    currency,
    shippingCost,
  } = convertDataIntoPaymentsObject(salesOrder);

  const paymentComplete = () => {
    const sellPrice = salesOrder.salesOrderGoodsItems?.reduce((result, item) => {
      result += Number(item.quantity.value) * Number(item.price.value);

      return result;
    }, 0);

    const subTotal =
      totalPayments - (paymentMethodCost + shippingCost - totalDiscount + totalRefunds);

    const difference = sellPrice - subTotal;

    return Math.round((difference + Number.EPSILON) * 100) / 100;
  };

  const handlePickDate = (date) => {
    csoManager.setDeadline(date);
  };

  const debouncedDatePick = useMemo(
    () =>
      debounce((date) => {
        handlePickDate(date);
      }, 500),
    []
  );

  const generateShippingMethod = () => {
    const currentMethod = salesOrder.shipping?.shippingMethod;
    const currentStreet = salesOrder.shipping?.deliveryAddress?.street;

    const found = getCSOShippingMethod(currentMethod, currentStreet);

    if (!found) {
      return null;
    }

    if (['Delivered', 'Express'].includes(found.method)) {
      return found.method + ' ' + salesOrder.shipping?.deliveryAddress?.country;
    } else if (found.method === 'LocalPickup') {
      return found.method + ' ' + found.address?.place;
    }

    return found.label + ' ' + found.address?.country;
  };

  const handleGoBack = () => {
    history.push(`/?${localStorage['opa-filters']}`);
  };

  const handleChangeStatus = (status) => {
    csoManager.setOrderStatus(status);
  };

  const getStatus = () => {
    if (salesOrder.status === 'NotAssigned') {
      return 'Not assigned';
    } else if (salesOrder === 'FullyAssigned') {
      return 'Fully assigned';
    } else {
      return 'Set status';
    }
  };

  const total = salesOrder?.requestedRefunds
    ?.reduce((a, v) => {
      return a + v.amount.value;
    }, 0)
    .toFixed(2);

  return (
    <>
      <Row style={{borderBottom: '1px solid #e3e3e3', paddingBottom: '0.5rem'}}>
        <Col className="d-flex justify-content-between align-items-center">
          <div>
            <span className="h4">
              <span
                style={{cursor: 'pointer', color: '#BA1E2B'}}
                onClick={handleGoBack}
                className="mr-4"
              >
                <IoArrowBack size={20} />
              </span>
              {`${salesOrder.buyer?.contact?.contactName} from ${salesOrder.buyer?.address?.place}`}
            </span>
          </div>
          {paymentComplete() ? (
            <div>
              <span className="h4">
                <FaExclamation color="red" />
                There is a payment difference of: {currency}
                {paymentComplete()}
                <FaExclamation color="red" />
              </span>
              <br />
              <span className="ml-4">
                {salesOrder?.requestedRefunds?.length > 0 &&
                  `Requested refunds: € ${total} (total)`}
              </span>
            </div>
          ) : null}

          {salesOrder.status === 'Cancelled' ? (
            <div>
              <span className="h4 mr-5">
                <FaExclamation color="red" />
                This order has been cancelled
                <FaExclamation color="red" />
              </span>
            </div>
          ) : null}
          <div>
            <Button
              title="View Soapi logic"
              size="sm"
              variant="primary"
              onClick={() => setShowModal({isVisible: true, type: 'soapi'})}
            >
              SOAPI
            </Button>

            <Button
              style={{marginLeft: '0.5rem'}}
              title="View order history"
              size="sm"
              variant="primary"
              onClick={() => setShowModal({isVisible: true, type: 'orderLog'})}
            >
              <FaBook />
            </Button>
            <Button
              title="Comments"
              style={{marginLeft: '0.5rem'}}
              size="sm"
              variant="primary"
              onClick={() => setShowModal({isVisible: true, type: 'add-seller-comment'})}
              disabled={salesOrder.hasNetSuiteId}
            >
              <SiGooglemessages />
            </Button>
            <Button
              size="sm"
              variant="outline-danger"
              style={{marginLeft: '0.5rem'}}
              onClick={() => toggleShowDetail(!showDetail)}
            >
              More info
            </Button>
          </div>
        </Col>
      </Row>

      <Row
        style={{
          marginBottom: '0.5rem',
          paddingBottom: '0.5rem',
        }}
      >
        <Col md={3} className="px-4">
          <Row className="d-flex justify-content-between">
            <span>
              <strong> Order id</strong>
            </span>
            <span>{salesOrder.id}</span>
          </Row>

          <Row className="d-flex justify-content-between">
            <span>
              <strong>NetSuite order-number</strong>
            </span>
            {salesOrder.hasNetSuiteId ? (
              <a
                target="_blank"
                rel="noreferrer"
                href={NETSUITE_ORDERS_URL + '?id=' + salesOrder.netSuiteId}
              >
                {internalOrderNumber}
              </a>
            ) : (
              <span>{internalOrderNumber}</span>
            )}
          </Row>
          <Row className="d-flex justify-content-between">
            <span>
              <strong>Delivery type</strong>
            </span>
            <span>
              {generateShippingMethod()}
              <Button
                title="Edit delivery"
                size="sm"
                className="ml-2 mb-1 text-decoration-none"
                variant="light"
                onClick={() => setShowModal({isVisible: true, type: 'change-shipping'})}
                disabled={salesOrder.hasNetSuiteId}
              >
                <FaEdit />
              </Button>
            </span>
          </Row>
          <Row className="d-flex justify-content-between">
            <span>
              <strong>Region</strong>
            </span>
            <span>{salesOrder.shipping?.deliveryAddress?.region}</span>
          </Row>
          {salesOrder.status === 'Cancelled' ? null : (
            <Row className="d-flex justify-content-between">
              <span>
                <strong>Status</strong>
              </span>
              <DropdownButton
                disabled={salesOrder.hasNetSuiteId}
                size="sm"
                className="d-inline ml-2"
                menuAlign="right"
                title={
                  salesOrderStatusOptions.filter(
                    (option) => option.value === salesOrder.status
                  )[0]?.label ?? getStatus()
                }
                variant="outline-secondary"
              >
                {salesOrderStatusOptions.map((option, index) => {
                  return (
                    <React.Fragment key={index}>
                      <Dropdown.Item onClick={() => handleChangeStatus(option.value)}>
                        {option.label}
                      </Dropdown.Item>
                      {index !== salesOrderStatusOptions.length - 1 && (
                        <Dropdown.Divider />
                      )}
                    </React.Fragment>
                  );
                })}
              </DropdownButton>
            </Row>
          )}
        </Col>
        <Col md={3}>
          <div className={'d-flex flex-column justify-content-between h-100'}>
            <div>
              <strong>Customer note</strong>
              <p>{salesOrder.customerComment}</p>
            </div>
          </div>
        </Col>
        <Col
          md={6}
          className="d-flex flex-column"
          style={{overflowY: 'auto', maxHeight: '15vh', minHeight: '200px'}}
        >
          {comments?.map((comment, i) => (
            <Row key={i}>
              <Comment comment={comment} />
            </Row>
          ))}
        </Col>
      </Row>
      <Row className="mt-auto d-flex justify-content-end mr-1 mb-2">
        <span className="mr-2 mt-1">
          <strong>Promised delivery date</strong>
        </span>
        <span>
          <DatePicker
            popperPlacement="bottom-end"
            dateFormat="dd-MM-yyyy"
            selected={deadLine ? new Date(deadLine) : ''}
            onChange={debouncedDatePick}
            isClearable
            disabled={salesOrder.hasNetSuiteId}
          />
        </span>
      </Row>

      <div
        className="toggle-wrapper"
        style={{
          maxHeight: showDetail ? '999px' : 0,
          overflow: 'hidden',
          transition: '.3s ease-in-out',
        }}
      >
        <OrderDetails salesOrder={salesOrder} />
      </div>

      <BaseModal
        isVisible={showModal.isVisible}
        onClose={() => setShowModal({isVisible: false, type: ''})}
        type={showModal.type}
        orderLog={salesOrder.orderLog}
        orderId={orderId}
        salesOrder={salesOrder}
        pickupLocation={pickupLocation}
        shippingMethod={shippingMethod}
      />
    </>
  );
};

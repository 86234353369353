import React from 'react';
import {any, number, oneOfType, string} from 'prop-types';

export const TotalSellPrice = ({amount, item}) => {
  const totalSellPrice = Number(amount) * Number(item.price.value);

  return (
    <p className="mb-0 h6 text-right">
      {item.allowedActions.hasOwnPrice ? (
        <>
          Selling price {item.price.currency} {totalSellPrice.toFixed(2)}{' '}
        </>
      ) : (
        <>No selling price</>
      )}
    </p>
  );
};

TotalSellPrice.propTypes = {
  amount: oneOfType([string, number]),
  item: any, // Gadero item object
};

import React, {useEffect, useState, useCallback} from 'react';
import {Button, Row, Col} from 'react-bootstrap';
import {useDispatch, useSelector} from 'react-redux';

import {getProductDetailsFromPIM, getProductsFromPIM} from '../../../apis/ApiCalls';
import LoadingSpinner from '../LoadingSpinner';
import ProductFinderInput from './ProductFinderInput';
import {notifyWarning} from '../../../utils/toasts';
import CSOManager from '../../../utils/csoManager/csoManager';
import {setLoading} from '../../../redux/actions/actions';

import SearchBar from '../form/SearchBar/SearchBar';
import {
  determineItemTypeOnPimProduct,
  productTypes,
} from '../../../utils/determineItemTypeOnPimProduct';

const ProductFinder = ({selectedItem, option, combiBuilder, handleAddProduct}) => {
  const [query, setQuery] = useState(
    selectedItem ? selectedItem.item?.name?.slice(8) : ''
  );
  const [foundProducts, setFoundProducts] = useState([]);
  const loading = useSelector((state) => state.loading);
  const dispatch = useDispatch();

  const findProducts = useCallback(
    async (value) => {
      setQuery(value);
      const response = await getProductsFromPIM({
        query: value,
        option: option,
      });

      setFoundProducts(response.data.products);
    },
    [option]
  );

  useEffect(() => {
    dispatch(setLoading(true));
    findProducts(query);
    dispatch(setLoading(false));
  }, [query, dispatch, findProducts]);

  const handleSubmitProduct = async (product, amount) => {
    if (!amount || amount === 0) {
      return notifyWarning('Please enter an amount');
    }

    if (combiBuilder) {
      product.quantity = {
        value: amount,
      };
      handleAddProduct(product);
    } else {
      const {data} = await getProductDetailsFromPIM(product.productId);

      if (determineItemTypeOnPimProduct(data) === productTypes.inventoryItem) {
        await CSOManager.addInventoryItemToSalesOrder(data, amount);
      } else if (determineItemTypeOnPimProduct(data) === productTypes.kitItem) {
        await CSOManager.addKitItemToSalesOrder(data, amount);
      } else {
        notifyWarning('Product type unknown');
      }
    }
  };

  const handleSubmitProductOption = async (product) => {
    const {data} = await getProductDetailsFromPIM(product.productId);

    await CSOManager.addProductOptionToSalesOrder(selectedItem, data);
  };

  const getArticleNumber = (product) => {
    if (product.articleNumbers.length === 0) {
      return 'Article number unknown';
    }

    return product.articleNumbers[0];
  };

  if (loading) {
    return <LoadingSpinner loading={loading} inline={false} />;
  }

  return (
    <div className="productFinder">
      <SearchBar
        placeholder="Product name or article number"
        onChange={(val) => findProducts(val)}
        value={query ?? ''}
      />

      {foundProducts?.length > 0 && (
        <>
          <Row className={'mt-2'}>
            <Col md={option ? 8 : 6}>
              <strong>Name</strong>
            </Col>
            {!option && (
              <Col md={3}>
                <strong>Article number</strong>
              </Col>
            )}
            <Col md={option ? 4 : 3}></Col>
          </Row>
          {foundProducts?.map((product, idx) => (
            <React.Fragment key={idx}>
              <Row className="mt-2">
                <Col md={option ? 8 : 6}>{product.name}</Col>
                {!option && <Col md={3}>{getArticleNumber(product)}</Col>}
                <Col md={option ? 4 : 3}>
                  {option && (
                    <div className="d-flex justify-content-around align-items-start">
                      <a
                        className="btn btn-sm btn-primary mr-2"
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`${process.env.REACT_APP_tafkap_url}product-options/${product.productId}/edit`}
                      >
                        View
                      </a>
                      <Button
                        size="sm"
                        onClick={() => handleSubmitProductOption(product)}
                      >
                        Add
                      </Button>
                    </div>
                  )}
                  {!option && (
                    <ProductFinderInput
                      handleSubmitProduct={handleSubmitProduct}
                      product={product}
                    />
                  )}
                </Col>
              </Row>
              <hr />
            </React.Fragment>
          ))}
        </>
      )}
    </div>
  );
};

export default ProductFinder;

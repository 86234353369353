import { postOrderStatus } from '../../../apis/ApiCalls';
import store from '../../../redux/store';
import { changeOrderStatus } from '../../../redux/actions/actions';
import { notifyError } from '../../toasts';

/**
 * Calls the backend API for setting the status of the CSO
 * @param {string} status
 * @returns {Promise<void>}
 */
export async function setOrderStatus(status) {
  const postObject = {
    orderId: this.id,
    eTag: new Date().toJSON(),
    status: status,
  };

  const response = await postOrderStatus(postObject);

  try {
    if (response.data.success) {
      store.dispatch(changeOrderStatus(status));
    }
  } catch (error) {
    notifyError(
      'Error: status niet geupdate, neem contact op met IT bij herhaling',
      error
    );
  }
}

import React, {Component} from 'react';
import Loader from 'react-loader-spinner';
import {Container, Col, Row, Button} from 'react-bootstrap';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import update from 'immutability-helper';
import qs from 'qs';
import {defaultFilters} from '../../../utils/data/defaultFilters';
import {
  getClientSalesOrders,
  getRegions,
  GetShippingMethods,
  getSuppliersFromInkoop,
} from '../../../apis/ApiCalls';

import Filters from './Filters';
import './OrderListPage.css';
import OverviewTable from './OverviewTable';
import * as XLSX from 'xlsx';
import {ticksToDateString} from '../../../utils/DateTimeUtils';

class OrderListPage extends Component {
  state = {
    itemsPerPage: 250,
    orderList: [],
    filters: {
      query: null,
      status: null,
      shippingMethod: null,
      country: null,
      region: [],
      supplierId: null,
      sortBy: 'orderCreated',
      reverseSort: true,
      deadline: null,
      inNetSuite: 'No',
    },
    isLoading: false,
    totalItems: 0,
    regions: [],
    shippingMethods: [],
    suppliers: [],
    currentPage: 1,
  };

  fetchOrders = async ({filters, minResults, maxResults}) => {
    this.setState({isLoading: true});
    minResults = minResults || 0;
    maxResults = maxResults || 250;

    let {data} = await getClientSalesOrders(filters, minResults, maxResults);

    this.setState({
      orderList: data.clientSalesOrders,
      isLoading: false,
      totalItems: data.totalHits,
      deadline: null,
    });
  };

  getQuery = () => {
    return qs.parse(this.props.location.search, {ignoreQueryPrefix: true});
  };

  setQuery = (query) => {
    this.props.history.push('?' + query);
  };

  componentDidMount() {
    const filters = this.getQuery();

    const currentPage = filters.currentPage;

    // clear the filters from localstorage
    localStorage.removeItem('opa-filters');

    // populate region default filters
    getRegions().then(({data}) => {
      defaultFilters.region = [
        {value: null, label: 'All'},
        ...data.map((region) => {
          return {value: region, label: region};
        }),
      ];

      this.setState({regions: data});
    });

    // populate shippingMethods default filters
    GetShippingMethods().then(({data}) => {
      defaultFilters.shippingMethod = [
        {value: null, label: 'All'},
        ...data.map((method) => {
          return {value: method, label: method};
        }),
      ];

      this.setState({shippingMethods: data});
    });

    getSuppliersFromInkoop().then(({data}) => {
      defaultFilters.supplier = [
        {value: null, label: 'All'},
        ...data.map((supplier) => {
          return {value: supplier?.reference, label: supplier?.contact?.name};
        }),
      ];

      this.setState({suppliers: data});
    });

    if (Object.entries(filters).length === 0) {
      const defaultFilters = {
        reverseSort: 'true',
        sortBy: 'orderCreated',
        inNetSuite: 'No',
      };
      this.fetchOrders({filters: defaultFilters});
    } else {
      const newState = update(this.state, {
        filters: {$set: filters},
      });

      this.setState(newState, () => {
        if (currentPage) {
          this.handlePaginate(Number(currentPage), newState.filters);
        } else {
          this.fetchOrders({
            filters,
          });
        }
      });
    }
  }

  onChangeSelect = (value, type) => {
    const newState = update(this.state, {
      filters: {[type]: {$set: value}},
      currentPage: {$set: 1},
    });

    this.setState(newState, () => {
      this.fetchOrders({
        filters: newState.filters,
      });
    });

    this.setUrlParams(newState.filters);
  };

  setUrlParams = (filters) => {
    const queryFromState = qs.stringify(filters, {
      skipNulls: true,
    });
    this.setQuery(queryFromState);
  };

  handlePaginate = (selectedPage, filters) => {
    const currentPage = Number(selectedPage);
    const maxResults = currentPage * this.state.itemsPerPage;
    const minResults = maxResults - this.state.itemsPerPage;

    this.setState({...filters, currentPage}, () => {
      this.fetchOrders({
        filters: filters ?? this.state.filters,
        minResults,
        maxResults,
      });
    });

    this.setUrlParams({...(filters ?? this.state.filters), currentPage});
  };

  handleSort = ({sort, reverse, currentPage}) => {
    const newState = update(this.state, {
      filters: {
        sortBy: {$set: sort},
        reverseSort: {$set: reverse},
      },
    });

    this.setState(newState, () => {
      this.handlePaginate(currentPage);
    });

    this.setUrlParams(newState.filters);
  };

  downloadExcel = (data) => {
    const formattedData = data.map((item) => ({
      ...item,
      deadline: ticksToDateString(item.deadline),
      orderCreated: ticksToDateString(item.orderCreated),
    }));
    const timestamp = new Date().toDateString();
    const filename = `Order_Overview_${timestamp}.xlsx`;

    const ws = XLSX.utils.json_to_sheet(formattedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, `${filename}`);
  };

  handleDownloadClick = () => {
    const {orderList} = this.state;

    if (orderList.length > 0) {
      this.downloadExcel(orderList);
    } else {
      alert('No data available for download.');
    }
  };

  render() {
    return (
      <Container className="orderlist-page">
        <Row>
          <Col lg={12}>
            <Filters
              onChange={this.onChangeSelect}
              defaultFilters={defaultFilters}
              currentFilters={this.state.filters}
            />
            <Button
              size={'sm'}
              className={'btn btn-primary mb-2 float-right'}
              onClick={this.handleDownloadClick}
              title={'Download as xlsx'}
            >
              Download as xlsx
            </Button>
          </Col>
        </Row>

        {this.state.isLoading ? (
          <Row className="loader-container">
            <Loader type="ThreeDots" color="#ba1e2b" />
          </Row>
        ) : (
          <OverviewTable
            totalItems={this.state.totalItems}
            orderList={this.state.orderList}
            currentPage={this.state.currentPage}
            sortBy={this.state.filters.sortBy}
            reverseSort={this.state.filters.reverseSort}
            onPaginate={this.handlePaginate}
            onSort={this.handleSort}
            deadline={this.state.filters.deadline}
          />
        )}
      </Container>
    );
  }
}

export default OrderListPage;

import React, {useMemo} from 'react';
import {format} from 'date-fns';

import ExpectedPurchaseOrdersTable from './ExpectedPurchaseOrdersTable';

const ExpectedPurchaseOrders = ({purchaseOrders}) => {
  const columns = useMemo(
    () => [
      {
        Header: 'Reference',
        accessor: 'reference',
      },
      {
        Header: 'Expected delivery date',
        accessor: 'expectedOn',
        Cell: ({value}) => {
          if (value) {
            return format(new Date(value), 'dd-MM-yyyy');
          } else {
            return 'Not yet known';
          }
        },
      },
      {
        Header: 'Amount',
        accessor: 'count',
      },
    ],
    []
  );

  return (
    <div className="mt-4">
      <h5>Inkooporders</h5>
      <ExpectedPurchaseOrdersTable data={purchaseOrders} columns={columns} />
    </div>
  );
};

export default ExpectedPurchaseOrders;

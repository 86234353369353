import React from 'react';
import {Card, Col, Row} from 'react-bootstrap';
import {FaEye} from 'react-icons/fa';

import {LinkButton} from '../../shared/buttons/LinkButton';

const OM_PRODUCT_URL = process.env.REACT_APP_om_url;

const ProductDetailsCard = ({productDetails}) => {
  return (
    <Card>
      <Card.Header className="d-flex">
        <h5>{productDetails.name}</h5>
        <LinkButton href={`${OM_PRODUCT_URL}${productDetails.omId}/open`}>
          <FaEye style={{marginLeft: '1em'}} color="red" />
        </LinkButton>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col>
            <strong>Article number: </strong>
          </Col>
          <Col className="pl-0">
            {productDetails.articleNumbers.map((articleNumber) => {
              let splitted = articleNumber.supplier.split('%20');

              return (
                <ul key={articleNumber.supplier}>
                  <li>
                    {splitted[0]} {splitted[1]} : {articleNumber.articleNumber}
                  </li>
                </ul>
              );
            })}
          </Col>
        </Row>

        <Row>
          <Col>
            <strong>EAN:</strong>
          </Col>
          <Col>{productDetails.ean}</Col>
        </Row>
        <Row>
          <Col>
            <strong>Price:</strong>
          </Col>
          <Col>{productDetails.salesPrice}</Col>
        </Row>
        <Row>
          <Col>
            <strong>Purchase price:</strong>
          </Col>
          <Col>{productDetails.purchasePrice?.value}</Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default ProductDetailsCard;

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSortDown,
  faSortUp,
  faSort,
} from '@fortawesome/free-solid-svg-icons';
import { bool, func, string } from 'prop-types';

const SortableTh = ({
  label,
  columnName,
  currentSortBy,
  currentReverseSort,
  onClick,
}) => {
  return (
    <th style={{ cursor: 'pointer' }} onClick={() => onClick(columnName)}>
      {label}{' '}
      {(currentSortBy !== columnName && (
        <FontAwesomeIcon icon={faSort} color="grey" />
      )) || (
        <FontAwesomeIcon icon={currentReverseSort ? faSortDown : faSortUp} />
      )}
    </th>
  );
};

SortableTh.propTypes = {
  label: string.isRequired,
  columnName: string.isRequired,
  currentSortBy: string,
  currentReverseSort: bool.isRequired,
  onClick: func.isRequired,
};

export default SortableTh;

import {deepCopy} from '../../deepCopy';

import {v4 as uuidv4} from 'uuid';
import store from '../../../redux/store';
import {deleteTypesFromGoodsItems} from '../../orderLines/deleteTypesFromGoodsItems';
import {updateGoodsItems} from '../../../apis/ApiCalls';
import {notifyError, notifyWarning} from '../../toasts';
import {refreshCSO} from '../refreshCSO';
import {rebuildSalesOrderGoodsItems} from '../../orderLines/transformSalesOrderGoodsItemsToOrderLines';

/**
 * This updated the sell price of the goodsItem on the orderLine and/or adds an
 * amount to the goodsItem
 * @param {string} newPrice The new price of the goodsItem
 * @param {string} amountToAdd The amount that will be added to the goodsItems
 * @param {object} item The selected GoodsItem
 * @returns {Promise<{success: boolean}>}
 */
export async function updatePriceAmountItem(newPrice, amountToAdd, item) {
  const newItem = deepCopy(item);
  newItem.price.value = Number(newPrice);

  const clonedOrderLines = deepCopy(store.getState().orderLines);
  const oldQuantity = Number(clonedOrderLines[item.orderlineId].quantity.value);

  clonedOrderLines[item.orderlineId].price.value = Number(newPrice);

  clonedOrderLines[item.orderlineId].quantity.value = oldQuantity + Number(amountToAdd);

  const childrenDictionary = store.getState().childrenDictionary;

  const updatedOrderLinesTree = rebuildSalesOrderGoodsItems(
    clonedOrderLines,
    childrenDictionary
  );

  const order = {
    orderId: this.current.id,
    orderType: 'salesOrder',
    transactionId: uuidv4(),
    GoodsItems: deleteTypesFromGoodsItems(updatedOrderLinesTree),
    eTag: new Date().toJSON(),
  };

  try {
    const {data} = await updateGoodsItems(order);

    if (data.success) {
      refreshCSO();
    } else {
      notifyWarning('Updaten is mislukt, neem contact op met IT');
    }
  } catch (error) {
    notifyError('Er is iets misgegaan, neem bij herhaling contact op met IT', error);
  }
}

import {deepCopy} from '../../utils/deepCopy';
import {
  ADD_NEW_COMMENT,
  CSO_INIT,
  RESET_CSO,
  SET_DEADLINE,
  SET_ORDER_NOT_FOUND,
  SET_ORDER_STATUS,
} from '../actions/actionTypes';

const initialState = {
  customerCommentChecked: false,
  orderFound: true,
};

const csoReducer = (state = initialState, action) => {
  let copyState = deepCopy(state);

  switch (action.type) {
    case CSO_INIT:
      return {...copyState, ...action.payload};

    case RESET_CSO:
      return initialState;

    case ADD_NEW_COMMENT:
      const {newComment} = action.payload;

      copyState = {
        ...copyState,
        sellerComments: [...copyState.sellerComments, newComment],
      };

      return copyState;

    case SET_DEADLINE:
      copyState = {
        ...copyState,
        deadLine: action.payload.date,
      };

      return copyState;

    case SET_ORDER_STATUS:
      copyState = {...copyState, status: action.payload.status};

      return copyState;

    case SET_ORDER_NOT_FOUND:
      return {...state, orderFound: false};

    default:
      return state;
  }
};

export default csoReducer;

/**
 * the purpose of this function is to add the orderlineId as the property
 originId from the original CSO-orderline on the purchaseorderGoodsItem.
 The goal is to make it so, that when a user removes an item from a PO it is
 possible to find and display the correct item on the screen.
 Example: 2 identical items in the CSO, one is a normal item, the other is the
 child of another item. Both are in seperate PO's and the user removes the one
 he thinks correlates to the one that is the child of another item. It would be
 strange to see the normal item return on the screen as unassigned.
 * @param {array} purchaseOrders The purchaseOrders on the CSO
 * @param {object} orderLinesObject The object containing all the salesOrderGoodsItems
 * @returns {*}
 */
export const enrichPurchaseOrdersWithOriginIds = (
  purchaseOrders,
  orderLinesObject
) => {
  Object.values(orderLinesObject).forEach((orderLine) => {
    if (orderLine.assignedPurchases.length > 0) {
      orderLine.assignedPurchases.forEach((assignedPurchase) => {
        purchaseOrders = purchaseOrders.map((purchaseOrder) => {
          if (purchaseOrder.id === assignedPurchase.purchaseOrderId) {
            purchaseOrder.goodsItems = purchaseOrder.goodsItems.map(
              (goodsItem) => {
                if (
                  goodsItem.item.id === orderLine.item.id &&
                  goodsItem.quantity.value ===
                    assignedPurchase.quantity.value &&
                  goodsItem.price.value === assignedPurchase.price.value
                ) {
                  goodsItem.originId = orderLine.orderlineId;
                }

                return goodsItem;
              }
            );
          }

          return purchaseOrder;
        });
      });
    }
  });

  return purchaseOrders;
};

import React from 'react';

import { ProductGroupWrapper } from '../../../shared/product/product-row-components';

import AssignableProductRow from './AssignableProductRow';
import ProductOptionRow from './ProductOptionRow';

const ProductOptionParentRow = ({
  item,
  childrenDictionary,
  orderLinesObject,
}) => {
  return (
    <ProductGroupWrapper>
      <AssignableProductRow item={item} />

      {childrenDictionary[item.orderlineId].map((el, index) => {
        if (!orderLinesObject[el].fullyAssigned) {
          return (
            <ProductOptionRow
              key={index}
              item={orderLinesObject[el]}
              orderLinesObject={orderLinesObject}
              childrenDictionary={childrenDictionary}
            />
          );
        } else {
          return null;
        }
      })}
    </ProductGroupWrapper>
  );
};
export default ProductOptionParentRow;

import {getProductDetailsFromPIM} from '../../apis/ApiCalls';

import {transformPIMProductToGoodsItem} from './transformPIMProductToGoodsItem';
import {goodsItemDecorator} from './addPropsToGoodsItems';

/**
 * Will query PIM for the subproducts of a goodsItem and tries to add them
 * @param {array} subProducts The id's of the subproducts
 * @param {object} parent The parent goodsItem to which the children are added
 * @returns {array} The children of the parent from PIM converted to the goodsItem format
 */
export const getSubItemsFromPIM = async (subProducts, parent) => {
  const subItems = [];

  for (const item of subProducts) {
    const {data} = await getProductDetailsFromPIM(item.id);

    const transformedSubItem = transformPIMProductToGoodsItem(data, item.quantity);

    transformedSubItem.suppliers = [];

    const newUpdatedSubItem = goodsItemDecorator(transformedSubItem, parent);

    subItems.push(newUpdatedSubItem);
  }

  return subItems;
};

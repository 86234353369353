export const defaultFilters = {
  status: [
    {value: 'NotAssigned', label: 'Not assigned'},
    {value: 'FullyAssigned', label: 'Fully assigned'},
    {value: 'PartiallyAssigned', label: 'Partially assigned'},
    {value: 'WaitingForPayment', label: 'Awaiting payment'},
    {value: 'Closed', label: 'Closed'},
    {value: 'Cancelled', label: 'Cancelled'},
    {value: 'Paused', label: 'Paused'},
    {value: 'Paved', label: 'Stone'},
    {value: 'Cabin', label: 'Log cabin'},
  ],
  country: [
    {value: 'All', label: 'All'},
    {value: 'NL', label: 'Netherlands'},
    {value: 'DE', label: 'Germany'},
    {value: 'BE', label: 'Belgium'},
    {value: 'FR', label: 'France'},
    {value: 'GB', label: 'GB'},
  ],
  deadlineFilterType: [
    {value: 'All', label: 'All'},
    {value: 'OnlyWhenSet', label: 'Set'},
    {value: 'OnlyWhenNull', label: 'Unset'},
  ],
  inNetSuite: [
    {value: 'All', label: 'All'},
    {value: 'No', label: 'No'},
    {value: 'Yes', label: 'Yes'},
  ],
  shippingMethod: [],
  region: [],
  supplier: [],
};

import { deepCopy } from '../deepCopy';

/**
 * Loops over the goodsItem array and checks compares the amount to be reduced
 * to the amount of the goodsItem, if more then current amount is being subtracted
 * the goodsItem is removed
 * @param {array} goodsItems Array of goodsItems which contains the selected goodsItem
 * @param {object} selectedProduct The selected goodsItem
 * @param {string | number} amount The amount to be subtracted
 * @returns {unknown[]}
 */
export const setAmountOrRemoveItem = (goodsItems, selectedProduct, amount) => {
  const clonedGoodsItems = deepCopy(goodsItems);

  const updatedGoodsItems = clonedGoodsItems
    .map((goodsItem) => {
      if (
        goodsItem.orderlineId !== selectedProduct.orderlineId &&
        goodsItem.item.subItems.length > 0
      ) {
        goodsItem.item.subItems = goodsItem.item.subItems
          .map((subItem) => {
            if (subItem.orderlineId === selectedProduct.orderlineId) {
              if (amount === subItem.quantity.value) {
                return null;
              } else if (amount < subItem.quantity.value) {
                subItem.quantity.value = subItem.quantity.value - amount;
              }
            }

            return subItem;
          })
          .filter((e) => !!e);
      } else if (goodsItem.orderlineId === selectedProduct.orderlineId) {
        if (amount === goodsItem.quantity.value) {
          return null;
        } else if (amount < goodsItem.quantity.value) {
          goodsItem.quantity.value = goodsItem.quantity.value - amount;
        }
      }

      return goodsItem;
    })
    .filter((v) => !!v);

  return updatedGoodsItems;
};

import {
  ADD_NEW_COMMENT,
  APP_LOADING,
  CSO_INIT,
  LOAD_CHILDRENDICTIONARY,
  LOAD_ORDERLINES,
  LOAD_PURCHASEORDERS,
  RESET_CSO,
  SET_DEADLINE,
  SET_ORDER_STATUS,
  SET_ORDER_NOT_FOUND,
} from './actionTypes';

export function loadCSO({childrenDictionary, orderLinesObject, purchaseOrders}) {
  return (dispatch) => {
    dispatch(updateDictionary(childrenDictionary));
    dispatch(updateOrderLines(orderLinesObject));
    dispatch(loadPurchaseOrders(purchaseOrders));
  };
}

export function resetCSO() {
  return {type: RESET_CSO};
}

export function updateDictionary(dictionary) {
  return {type: LOAD_CHILDRENDICTIONARY, payload: dictionary};
}

export function updateOrderLines(orderLines) {
  return {type: LOAD_ORDERLINES, payload: orderLines};
}

export function setLoading(isLoading) {
  return {type: APP_LOADING, payload: isLoading};
}

export function setOrderNotFound() {
  return {type: SET_ORDER_NOT_FOUND};
}

export function initCSO(salesOrder) {
  return {type: CSO_INIT, payload: salesOrder};
}

export function loadPurchaseOrders(purchaseOrders) {
  return {type: LOAD_PURCHASEORDERS, payload: purchaseOrders};
}

export function addNewComment(newComment) {
  return {
    type: ADD_NEW_COMMENT,
    payload: {
      newComment: newComment,
    },
  };
}

export function setDeadlineInState(date) {
  return {
    type: SET_DEADLINE,
    payload: {
      date: date,
    },
  };
}

export function changeOrderStatus(status) {
  return {
    type: SET_ORDER_STATUS,
    payload: {
      status: status,
    },
  };
}

export function clearCSO() {
  return (dispatch) => {
    dispatch(resetCSO());
    dispatch(updateDictionary({}));
    dispatch(updateOrderLines({}));
    dispatch(loadPurchaseOrders([]));
    dispatch(setLoading(true));
  };
}

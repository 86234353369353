import {initCSO, loadCSO, setLoading} from '../redux/actions/actions';
import store from '../redux/store';

import {deepCopy} from './deepCopy';
import {addPropsToGoodsItems} from './orderLines/addPropsToGoodsItems';
import {buildChildrenDictionary} from './dictionary/buildChildrenDictionary';
import {transformSalesOrderGoodsItemsToOrderLines} from './orderLines/transformSalesOrderGoodsItemsToOrderLines';
import {enrichPurchaseOrdersWithOriginIds} from './enrichPurchaseOrdersWithOriginIds';

/**
 * Initializes the state in the Redux store, orderLinesObject contains all the goodsItems,
 * the childrenDictionary is used to navigate the orderLinesObject
 * @param {object} data The salesOrder as it comes from the backend
 */
export const initializeState = (data) => {
  const clonedSalesOrder = deepCopy(data);

  clonedSalesOrder.salesOrderGoodsItems = addPropsToGoodsItems(
    clonedSalesOrder.salesOrderGoodsItems
  );

  const childrenDictionary = buildChildrenDictionary(
    clonedSalesOrder.salesOrderGoodsItems
  );

  const orderLinesObject = transformSalesOrderGoodsItemsToOrderLines(
    clonedSalesOrder.salesOrderGoodsItems
  );

  const purchaseOrders = enrichPurchaseOrdersWithOriginIds(
    data.purchaseOrders,
    orderLinesObject
  );

  store.dispatch(initCSO(data));

  store.dispatch(
    loadCSO({
      childrenDictionary,
      orderLinesObject,
      purchaseOrders,
    })
  );

  store.dispatch(setLoading(false));
};

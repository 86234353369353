import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {Modal, Button, Container, Card, Row, Col} from 'react-bootstrap';
import {StickyModalFooter} from '../StickyModalFooter';
import {format} from 'date-fns';
import {setLoading} from '../../../../redux/actions/actions';
import {getSoapiDetails} from '../../../../apis/ApiCalls';
import {useParams} from 'react-router-dom';
import {TimelineContainer, TimeLineEntry} from './TimeLineEntry';

const MutedParagraph = styled.p`
  color: hsl(0, 0%, 80%);
  font-size: 15px;
`;

const SoapiLogicModal = ({handleClose, salesOrderShippingMethod, pickupLocation}) => {
  const {orderId} = useParams();
  const [soapiLogic, setSoapiLogic] = useState(null);
  const [showOrderLines, setShowOrderLines] = useState(false);

  const {shippingTimeline, shippingMethod, totalPurchaseCost} = soapiLogic || {};

  const {
    orderPlacedBy,
    orderProcessedBy,
    allDeliveredToTransshipmentBy,
    completedPickingAtTransshipmentBy,
    allDeliveredToPickupLocationBy,
    completedPickingAtPickupLocationBy,
    earliestPickupWindowStart,
    allDeliveredToCustomerBy,
  } = shippingTimeline || {};

  useEffect(() => {
    const fetchSoapiLogic = async () => {
      setLoading(true);

      try {
        const {data} = await getSoapiDetails(
          orderId,
          salesOrderShippingMethod,
          pickupLocation
        );

        if (data) {
          setSoapiLogic(data);
        }
      } catch (error) {
        console.error('Error fetching soapiLogic:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchSoapiLogic();
  }, [orderId, salesOrderShippingMethod, pickupLocation]);

  return (
    <>
      <Modal.Header>
        <Modal.Title>SOAPI</Modal.Title>
      </Modal.Header>
      <Modal.Body className={'w-100'}>
        {soapiLogic ? (
          <>
            <Row>
              <Col className={'col-5'}>
                <Card className={'bg-dark p-4 mt-2'} style={{height: 'auto'}}>
                  <MutedParagraph>Shipping method: {shippingMethod}</MutedParagraph>
                  {soapiLogic.transshipmentLocation && (
                    <MutedParagraph>
                      Transshipment: {soapiLogic?.transshipmentLocation}
                    </MutedParagraph>
                  )}
                  {soapiLogic.pickupLocation && (
                    <MutedParagraph>Pickup location: {pickupLocation} </MutedParagraph>
                  )}

                  <MutedParagraph>
                    Indirect delivery: {shippingMethod?.isIndirectDelivery ? 'yes' : 'no'}
                  </MutedParagraph>
                  <MutedParagraph>
                    Total: {totalPurchaseCost?.currency}{' '}
                    {totalPurchaseCost?.value.toFixed(2)}
                  </MutedParagraph>
                  <Button
                    variant="outline-info"
                    className={'mt-5'}
                    onClick={() => setShowOrderLines(!showOrderLines)}
                  >
                    {showOrderLines ? 'Hide orderlines' : 'Show orderlines'}
                  </Button>
                </Card>
              </Col>
              <Col>
                <Container className="py-2">
                  <TimelineContainer>
                    {orderPlacedBy && (
                      <TimeLineEntry
                        title="Last possible order moment for customer"
                        date={orderPlacedBy}
                      />
                    )}
                    {orderProcessedBy && (
                      <TimeLineEntry title="order processed" date={orderProcessedBy} />
                    )}
                    {allDeliveredToTransshipmentBy && (
                      <TimeLineEntry
                        title="item(s) delivered to transshipment"
                        date={allDeliveredToTransshipmentBy}
                      />
                    )}
                    {completedPickingAtTransshipmentBy && (
                      <TimeLineEntry
                        title="order picked at transshipment"
                        date={completedPickingAtTransshipmentBy}
                      />
                    )}
                    {allDeliveredToPickupLocationBy && (
                      <TimeLineEntry
                        title="item(s) delivered to pickup location"
                        date={allDeliveredToPickupLocationBy}
                      />
                    )}
                    {completedPickingAtPickupLocationBy && (
                      <TimeLineEntry
                        title="order ready at pickup location"
                        date={completedPickingAtPickupLocationBy}
                      />
                    )}
                    {earliestPickupWindowStart && (
                      <TimeLineEntry
                        title="first pickup window for customer"
                        date={earliestPickupWindowStart}
                      />
                    )}
                    {allDeliveredToCustomerBy && (
                      <TimeLineEntry
                        title="promised deliverydate at customer"
                        date={allDeliveredToCustomerBy}
                      />
                    )}
                  </TimelineContainer>
                </Container>
              </Col>
            </Row>

            {showOrderLines && (
              <Container>
                <hr className={'mt-5'} />
                <p>Orderlines</p>
                <ul>
                  {soapiLogic?.orderlines?.map((orderline, idx) => {
                    return (
                      <>
                        <li key={idx}>Destination: {orderline?.destination} </li>
                        <li>Shipping method: {orderline?.shippingMethod} </li>
                        <li>
                          Expected on:{' '}
                          {orderline?.expectedBy
                            ? format(new Date(orderline?.expectedBy), 'dd-MM-yyyy HH:mm')
                            : 'nvt'}{' '}
                        </li>
                        <li>Amount: {orderline?.quantity} </li>
                        <li>Supplier: {orderline?.supplier}</li>
                        <li>
                          Amount: {orderline?.purchaseCost?.currency}{' '}
                          {orderline?.purchaseCost?.value.toFixed(2)}
                        </li>
                        <li>
                          Product
                          <ul>
                            <li>name: {orderline.product.name}</li>
                            <li>ID: {orderline.product.id}</li>
                            <li>
                              Has parent: {orderline.product.hasParent ? 'ja' : 'nee'}
                            </li>
                            <li>
                              Is SubItem: {orderline.product.isSubProduct ? 'ja' : 'nee'}
                            </li>
                            <li>
                              Is Option:{' '}
                              {orderline.product.isOptionProduct ? 'ja' : 'nee'}
                            </li>
                            <li>
                              Root ID:{' '}
                              {orderline.product.rootId
                                ? orderline.product.rootId
                                : 'nvt'}
                            </li>
                            <li>
                              PATH
                              <ul>
                                {soapiLogic?.orderlines?.map((orderline) => {
                                  return orderline?.product?.path?.map((path) => {
                                    return (
                                      <>
                                        <li>Name: {path.name}</li>
                                        <li>Type: {path.type}</li>
                                        <li>ID: {path.id}</li>
                                      </>
                                    );
                                  });
                                })}
                              </ul>
                            </li>
                          </ul>
                        </li>
                      </>
                    );
                  })}
                </ul>
              </Container>
            )}
          </>
        ) : (
          <div className={'text-center'}>
            <b>{'No Data'}</b>
          </div>
        )}
      </Modal.Body>
      <StickyModalFooter>
        <Button variant="danger" onClick={handleClose}>
          Close
        </Button>
      </StickyModalFooter>
    </>
  );
};

export default SoapiLogicModal;


import React from 'react';
import {Card, Col, Row} from 'react-bootstrap';

import {ProductCard, ProductCardHeader} from '../../../shared/product/ProductCard';
import {ProductCardTitle} from '../../../shared/product/ProductCardTitle';

import AssignedProductRow from './AssignedProductRow';
import {productTypes} from '../../../../utils/determineItemTypeOnPimProduct';

const AssignedProductsDisplay = ({orderLinesObject, purchaseOrders}) => {
  const assignedItems = Object.values(orderLinesObject).filter(
    (el) => el.fullyAssigned && el.item.itemType === productTypes.inventoryItem
  );

  return (
    <>
      {assignedItems.length > 0 ? (
        <div className="mt-2">
          <ProductCard>
            <ProductCardHeader>
              <Row className="align-items-center">
                <Col>
                  <ProductCardTitle
                    value="Assigned products"
                    amount={assignedItems.length}
                  />
                </Col>
              </Row>
            </ProductCardHeader>

            <hr />
            <Card.Body>
              {assignedItems.map((item, index) => (
                <AssignedProductRow
                  key={index}
                  item={item}
                  purchaseOrders={purchaseOrders}
                />
              ))}
            </Card.Body>
          </ProductCard>
        </div>
      ) : null}
    </>
  );
};

export default AssignedProductsDisplay;

/**
 * Cornerstone of the app, takes in the goodsItems from the CSO and builds up
 * the orderLines object
 * @param {array} goodsItems The goodsItems from the CSO
 * @returns {{}}
 */
export const transformSalesOrderGoodsItemsToOrderLines = (goodsItems) => {
  const orderLines = {};

  const processItems = (items) => {
    items.forEach((item) => {
      orderLines[item.orderlineId] = item;

      if (item.item.subItems.length > 0) {
        processItems(item.item.subItems);
      }
    });
  };

  processItems(goodsItems);

  return orderLines;
};

export const rebuildSalesOrderGoodsItems = (orderLines, childrenDictionary) => {
  const buildNestedArray = (parentId) => {
    const childrenIds = childrenDictionary[parentId];

    if (!childrenIds || childrenIds.length === 0) {
      return orderLines[parentId];
    }

    const parentItem = {...orderLines[parentId]};
    parentItem.item.subItems = childrenIds.map((childId) => buildNestedArray(childId));

    return parentItem;
  };

  // Find the root level items
  const rootItems = Object.keys(orderLines).filter(
    (orderId) => !Object.values(childrenDictionary).flat().includes(orderId),
  );

  // Rebuild the nested array for each root item and return it
  return rootItems.map((rootId) => buildNestedArray(rootId));
};

/**
 * This will remove any types set by the backend on goodsItems to prevent the
 * backend from breaking by accidentally sending the wrong type
 * @param {array} goodsItems The goodsItem of which the types will be removed
 * @returns {array} Returns and array of the sanitized goodsItems
 */
export const deleteTypesFromGoodsItems = (goodsItems) => {
  const deleteTypes = (item) => {
    if (item) {
      delete item.$type;

      // Delete $type properties for price, item, and quantity if they exist
      ['price', 'item', 'quantity'].forEach(prop => {
        if (item[prop]) {
          delete item[prop].$type;
        }
      });

      if (item.suppliers?.length > 0) {
        item.suppliers.forEach((supplier) => {
          delete supplier.$type;

          if (supplier.stock) delete supplier.stock.$type;

          if (supplier.staffels?.length > 0) {
            supplier.staffels.forEach((staffel) => {
              delete staffel.$type;
            });
          }

          deleteTypes(supplier);
        });
      }

      if (item.item?.subItems?.length > 0) {
        item.item.subItems.forEach((subItem) => {
          deleteTypes(subItem);
        });
      }
    }
  };

  goodsItems.forEach((goodsItem) => {
    deleteTypes(goodsItem);
  });

  return goodsItems;
};

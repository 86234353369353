export const productTypes = {
  kitItem: 'KitItem',
  inventoryItem: 'InventoryItem',
  optionItem: 'Option',
};

export const determineItemTypeOnPimProduct = (pimProduct) => {
  if (pimProduct.subProducts?.length > 0) {
    if (pimProduct.optionCategory?.id === 0) {
      return productTypes.kitItem;
    } else {
      return productTypes.optionItem;
    }
  }

  return productTypes.inventoryItem;
};

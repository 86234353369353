import React, {useState} from 'react';
import {Button, Card} from 'react-bootstrap';

import {BaseModal} from '../../../../shared/modals/BaseModal';
import {convertDataIntoPaymentsObject} from '../../../../../utils/convertDataIntoPaymentsObject';
import {CostCol, PaymentCol, PaymentsRow, Title} from './paymentDisplayComponents';

export const Payment = ({salesOrder}) => {
  const [showModal, setShowModal] = useState({isVisible: false, type: ''});

  const {
    totalDiscount,
    totalRefunds,
    totalPayments,
    paymentMethodCost,
    currency,
    shippingCost = 0,
  } = convertDataIntoPaymentsObject(salesOrder);

  const sellPrice = salesOrder.salesOrderGoodsItems?.reduce((result, item) => {
    result += Number(item.quantity.value) * Number(item.price.value);

    return result;
  }, 0);

  const calculateSubTotal = () => {
    return paymentMethodCost + shippingCost + sellPrice - totalDiscount - totalRefunds;
  };

  return (
    <>
      <Card>
        <Card.Header>Transactions</Card.Header>
        <Card.Body>
          <PaymentsRow>
            <Title>Payments:</Title>
            <PaymentCol>
              {currency} {totalPayments?.toFixed(2)}
            </PaymentCol>
          </PaymentsRow>

          <PaymentsRow>
            <Title>Payment fees:</Title>
            <CostCol>
              {currency} {paymentMethodCost?.toFixed(2)}
            </CostCol>
          </PaymentsRow>

          <PaymentsRow>
            <Title>Shipping fees:</Title>
            <CostCol>
              {currency} {shippingCost?.toFixed(2)}
            </CostCol>
          </PaymentsRow>

          <PaymentsRow>
            <Title>Discount:</Title>
            <CostCol>
              {currency} {totalDiscount?.toFixed(2)}
            </CostCol>
          </PaymentsRow>

          <PaymentsRow>
            <Title>Refunds:</Title>
            <CostCol>
              {currency} {totalRefunds?.toFixed(2)}
            </CostCol>
          </PaymentsRow>

          <PaymentsRow>
            <Title>Sales value:</Title>
            <CostCol bottomLine>
              {currency} {sellPrice?.toFixed(2)}
            </CostCol>
          </PaymentsRow>

          <PaymentsRow>
            <Title>Subtotal:</Title>
            <PaymentCol bottomLine>
              {currency} {calculateSubTotal()?.toFixed(2)}
            </PaymentCol>
          </PaymentsRow>

          <PaymentsRow>
            <Title>Difference:</Title>
            <PaymentCol>
              {currency} {(totalPayments - calculateSubTotal())?.toFixed(2)}
            </PaymentCol>
          </PaymentsRow>
        </Card.Body>

        <Card.Footer className="d-flex justify-content-between">
          <Button
            size="sm"
            variant="outline-secondary"
            onClick={() => setShowModal({isVisible: true, type: 'payment-details'})}
          >
            Details
          </Button>
        </Card.Footer>
      </Card>

      <BaseModal
        isVisible={showModal?.isVisible}
        type={showModal.type}
        paymentDetails={salesOrder.payments}
        option={false}
        onClose={() => setShowModal({isVisible: false, type: null})}
      />
    </>
  );
};

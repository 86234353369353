import styled from 'styled-components';
import {Card} from 'react-bootstrap';

import Box from '../../../shared/layout/box/box';

export const PurchaseOrderCardHeader = styled(Card.Header)`
  background-color: ${(props) =>
    props.pickingstatus === 'Printed'
      ? '#FF5733'
      : props.pickingstatus !== 'Open'
      ? '#d22e2e'
      : ''};

  & > * > * {
    color: ${(props) =>
      props.pickingstatus === 'Printed'
        ? '#000'
        : props.pickingstatus !== 'Open'
        ? '#FFF'
        : ''};
  }
`;

export const PurchaseOrderGrid = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-areas:
    'deliveryGadero deliveryGadero deliveryCustomer deliveryCustomer orderDetails'
    'deliveryMethod deliveryMethod transporter transporter colli'
    'tntUrl tntUrl tntUrl tntUrl tntUrl';
`;

export const DeliveryGadero = styled.div`
  grid-area: deliveryGadero;
`;

export const DeliveryCustomer = styled.div`
  grid-area: deliveryCustomer;
`;

export const OrderDetails = styled.div`
  grid-area: orderDetails;
  align-self: center;
`;

export const DeliveryMethod = styled.div`
  grid-area: deliveryMethod;
`;

export const Transporter = styled.div`
  grid-area: transporter;
`;

export const Colli = styled.div`
  grid-area: colli;
`;

export const BlockLabel = styled.label`
  display: block;
`;

export const BottomBox = styled(Box)`
  padding-top: 0;
`;

export const TntUrl = styled.div`
  grid-area: tntUrl;
`;

import styled, { css } from 'styled-components';
import { Modal } from 'react-bootstrap';

export const StickyModalFooter = styled(Modal.Footer)`
  position: sticky;
  bottom: 0;
  background-color: white;
  overflow: hidden;
  ${({ showShadow = false }) =>
    showShadow &&
    css`
      box-shadow: 0 -4px 4px -2px #999;
    `};
`;

export const SET_NAME = 'name';
export const SET_CONTACT_NAME = 'contactName';
export const SET_PHONE = 'phone';
export const SET_EMAIL = 'email';
export const SET_STREET = 'street';
export const SET_NUMBER = 'number';
export const SET_NUMBER_ADDITION = 'numberAddition';
export const SET_PLACE = 'place';
export const SET_ZIP = 'zip';
export const SET_COUNTRY_CODE = 'countryCode';
export const SET_CHAMBER_OF_COMMERCE_NUMBER = 'chamberOfCommerceNumber';
export const SET_VAT_NUMBER = 'vatNumber';
export const SET_REFERENCE = 'reference';

export const customerInformationReducer = (state, action) => {
  switch (action.type) {
    case SET_NAME:
      return {...state, name: action.payload};
    case SET_CONTACT_NAME:
      return {...state, contactName: action.payload};
    case SET_PHONE:
      return {...state, phone: action.payload};
    case SET_EMAIL:
      return {...state, email: action.payload};
    case SET_STREET:
      return {...state, street: action.payload};
    case SET_NUMBER:
      return {...state, number: action.payload};
    case SET_NUMBER_ADDITION:
      return {...state, numberAddition: action.payload};
    case SET_PLACE:
      return {...state, place: action.payload};
    case SET_ZIP:
      return {...state, zip: action.payload};
    case SET_COUNTRY_CODE:
      return {...state, countryCode: action.payload};
    case SET_CHAMBER_OF_COMMERCE_NUMBER:
      return {...state, chamberOfCommerceNumber: action.payload};
    case SET_VAT_NUMBER:
      return {...state, vatNumber: action.payload};
    case SET_REFERENCE:
      return {...state, reference: action.payload};
    default:
      return state;
  }
};

import React from 'react';
import styled from 'styled-components';
import { CircleSpinner } from 'react-spinners-kit';
import PropTypes from 'prop-types';

const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 1rem;
  height: ${({ inline }) => (inline ? '100%' : '90vh')};
`;

const LoadingSpinner = ({ loading, inline }) => {
  return (
    <SpinnerWrapper inline={inline}>
      <CircleSpinner size={30} color="#686769" loading={loading} />
    </SpinnerWrapper>
  );
};

LoadingSpinner.propTypes = {
  loading: PropTypes.bool.isRequired,
  inline: PropTypes.bool.isRequired,
};

export default LoadingSpinner;

import './shared/variables.css';
import 'bootstrap/dist/css/bootstrap.css';
import {BrowserRouter as Router, Switch, Route, Redirect} from 'react-router-dom';
import {Provider} from 'react-redux';
import {OidcProvider} from 'redux-oidc';
import 'react-toastify/dist/ReactToastify.css';
import {ToastContainer} from 'react-toastify';

import userManager from '../utils/userManager';
import store from '../redux/store';

import Callback from './auth/Callback';
import Navigation from './nav/Navigation';
import OrderListPage from './pages/OrderListPage/OrderListPage';
import PrivateRoute from './auth/PrivateRoute';
import ClientSalesOrderEdit from './pages/ClientSalesOrderEdit/ClientSalesOrderEdit';
import ProductStockInformationPage from './pages/ProductDetailsPage/ProductStockInformationPage';

function App() {
  const redirectToHome = () => {
    userManager.clearStaleState();

    return <Redirect to="/" />;
  };

  return (
    <Provider store={store}>
      <OidcProvider store={store} userManager={userManager}>
        <Router>
          <Navigation />
          <Switch>
            <PrivateRoute
              role="Order Processor"
              exact
              path="/"
              component={OrderListPage}
            />
            <PrivateRoute
              role="Order Processor"
              path="/edit/client-sales-order/:orderId"
              component={ClientSalesOrderEdit}
            />
            <PrivateRoute
              role="Order Processor"
              path="/productdetails/:productId"
              component={ProductStockInformationPage}
            />
            <Route exact path="/signin-oidc" component={Callback} />
            <Route component={redirectToHome} />
          </Switch>
          <ToastContainer />
        </Router>
      </OidcProvider>
    </Provider>
  );
}

export default App;

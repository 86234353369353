import styled from 'styled-components';

export const PaymentsRow = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: auto;
  grid-template-areas: 'a a b c';
  align-items: center;
`;

export const Title = styled.div`
  grid-area: a;
`;

export const PaymentCol = styled.div`
  grid-area: c;
  justify-self: end;
  font-weight: bold;
  border-bottom: ${(props) => (props.bottomLine ? '1px solid black' : 'none')};
`;

export const CostCol = styled.div`
  grid-area: b;
  justify-self: end;
  font-weight: bold;
  border-bottom: ${(props) => (props.bottomLine ? '1px solid black' : 'none')};
`;

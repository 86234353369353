/**
 * merges 2 orderlines objects to 1
 * Will replace duplicates
 * @param {object} a orderlines object
 * @param {object} b orderlines object
 */
export const mergeOrderlines = (a, b) => {
  let result = { ...a };

  for (const key in b) {
    const value = b[key];
    result[key] = value;
  }

  return result;
};

import {countryCodeDictionary} from '../../../../../shared/modals/change-shipping-modal/country-config';

export const postObjectBuilder = (state, orderId, buyer, latLong) => {
  let propertyName = buyer ? 'Buyer' : 'Receiver';

  return {
    orderId,
    eTag: new Date().toJSON(),
    orderType: 'SalesOrder',
    [propertyName]: {
      contact: {
        name: state.name,
        contactName: state.contactName,
        phone: state.phone,
        email: state.email,
      },
      address: {
        street: state.street,
        number: state.number,
        numberAddition: state.numberAddition,
        zip: state.zip,
        place: state.place,
        country: countryCodeDictionary[state.countryCode],
        countryCode: state.countryCode,
        latLong,
      },
      ...(buyer && {
        chamberOfCommerceNumber: state.chamberOfCommerceNumber,
        vatNumber: state.vatNumber,
        reference: state.reference,
      }),
    },
  };
};

export const initialShippingMethod = {
  label: 'Via overslag',
  value: 'gadero-groningen-trans-shipment',
  method: 'TransShipment',
  address: {
    street: 'Euvelgunnerweg',
    number: '19',
    numberAddition: '',
    zip: '9723 CT',
    place: 'Groningen',
    country: 'Nederland',
    countryCode: 'NL',
  },
};

import {Row, Col} from 'react-bootstrap';
import React from 'react';
import styled from 'styled-components';
import {useSelector} from 'react-redux';

const FooterWrapper = styled.div`
  background-color: #f8f8f8;
  border-top: 1px solid #e7e7e7;
  text-align: center;
  padding: 20px;
  position: fixed;
  left: 0;
  bottom: 0;
  height: 60px;
  width: 100%;
  z-index: 1;
`;

const Space = styled.div`
  display: block;
  padding: 20px;
  height: 60px;
  width: 100%;
`;

const Text = styled.p`
  padding-right: 20px;
`;

export const PageFooter = ({salesOrderGoodsItems}) => {
  const purchaseOrders = useSelector((state) => state.purchaseOrders);

  const sellPrice = Object.values(salesOrderGoodsItems).reduce((result, item) => {
    if (!item.parent) {
      result += Number(item.quantity?.value) * Number(item.price?.value);
    }

    return result;
  }, 0);

  const costPrice = purchaseOrders.reduce((result, item) => {
    let val = item.goodsItems.reduce((res, good) => {
      return (res += Number(good.quantity?.value) * Number(good.price?.value));
    }, 0);
    result += val;

    return result;
  }, 0);

  return (
    <div>
      <Space />
      <FooterWrapper>
        <Row>
          <Col>
            <Text className="mb-0 text-center">
              <strong>Selling price: € {sellPrice.toFixed(2)}</strong>
            </Text>
          </Col>
          <Col md={5}>
            <Text className="mb-0 text-center">
              <strong>Purchase price: € {costPrice.toFixed(2)}</strong>
            </Text>
          </Col>
        </Row>
      </FooterWrapper>
    </div>
  );
};

import React from 'react';
import {Route} from 'react-router-dom';
import {connect} from 'react-redux';

import userManager from '../../utils/userManager';

function PrivateRoute({component: Component, role, ...rest}) {
  let redirecting = false;

  return (
    <Route
      {...rest}
      render={(props) => {
        if (rest.oidc.isLoadingUser) return null; //don't know still starting up

        if (!rest.oidc.user || rest.oidc.user.expired) {
          if (!redirecting) {
            localStorage.setItem('redirectUri', window.location.href);
            userManager.clearStaleState();
            userManager.signinRedirect();
            redirecting = true;
          }

          return <div>Redirecting to login page...</div>;
        }
        redirecting = false;

        if (role) {
          if (
            !rest.oidc.user.profile.roles ||
            !rest.oidc.user.profile.roles.includes(role)
          ) {
            return <p>You don't have the correct permissions</p>;
          }
        }

        return <Component {...props} />;
      }}
    />
  );
}

const mapStateToProps = (state) => ({
  oidc: state.oidc,
});

export default connect(mapStateToProps)(PrivateRoute);

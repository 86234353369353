import React from 'react';
import {connect} from 'react-redux';
import {CallbackComponent} from 'redux-oidc';

import {notifyError} from '../../utils/toasts';
import userManager from '../../utils/userManager';

class CallbackPage extends React.Component {
  clearStaleState = async () => {
    try {
      await userManager.clearStaleState();
    } catch (error) {
      notifyError('Something went wrong, please contact IT');
    }
  };

  render() {
    return (
      <CallbackComponent
        userManager={userManager}
        successCallback={() => {
          let pathName = localStorage.getItem('redirectUri');

          this.clearStaleState();
          localStorage.removeItem('redirectUri');
          window.location = pathName ? pathName : process.env.PUBLIC_URL;
        }}
        errorCallback={(error) => console.error(error)}
      >
        <div>Redirecting...</div>
      </CallbackComponent>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.oidc.user,
});

export default connect(mapStateToProps)(CallbackPage);

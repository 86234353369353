import React, {useRef} from 'react';
import {Modal, Button, InputGroup, FormControl, Col, Row} from 'react-bootstrap';

import CSOManager from '../../../utils/csoManager/csoManager';

const ChangeProductModal = ({title, handleClose, item}) => {
  const price = useRef(null);
  const amount = useRef(null);

  const handleChangeProduct = (newPrice, amountToAdd) => {
    CSOManager.updatePriceAmountItem(newPrice, amountToAdd, item);
  };

  return (
    <>
      <Modal.Header>
        <Modal.Title>{title ?? 'Edit?'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md={6}>
            <label>
              <span>Selling price per unit</span>
              <InputGroup className="mb-3">
                <InputGroup.Prepend>
                  <InputGroup.Text id="payment-input">
                    {item?.price.currency}
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  placeholder="0"
                  aria-label="Amount"
                  aria-describedby="payment-input"
                  ref={price}
                  type="number"
                  min={0}
                  defaultValue={item?.price.value}
                />
              </InputGroup>
            </label>
          </Col>
          <Col md={6}>
            <label>
              <span>Add amount</span>
              <InputGroup className="mb-3">
                <FormControl
                  placeholder="0"
                  aria-label="Amount"
                  ref={amount}
                  type="number"
                  min={0}
                  defaultValue={0}
                />
              </InputGroup>
            </label>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={handleClose}>
          Close
        </Button>
        <Button
          variant="success"
          onClick={() =>
            handleChangeProduct(price?.current?.value, amount?.current?.value)
          }
        >
          Save
        </Button>
      </Modal.Footer>
    </>
  );
};

export default ChangeProductModal;

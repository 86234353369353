import React from 'react';
import {useTable, useRowSelect} from 'react-table';
import styled from 'styled-components';

import Stack from '../../../shared/layout/stack/stack';

const TableWrapper = styled(Stack)`
  table {
    th,
    td {
      padding: 0.25rem 0.5rem;
    }
  }
`;

const IndeterminateCheckbox = React.forwardRef(({indeterminate, ...rest}, ref) => {
  const defaultRef = React.useRef();
  const resolvedRef = ref || defaultRef;

  React.useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return <input type="checkbox" ref={resolvedRef} {...rest} />;
});

IndeterminateCheckbox.displayName = 'IndeterminateCheckbox';

function Table({columns, data}) {
  const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow} = useTable(
    {
      columns,
      data,
    },
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        ...columns,
        {
          id: 'selection',
          Header: Header,
          Cell: Cell,
        },
      ]);
    }
  );

  function Header() {
    return <div></div>;
  }

  function Cell() {
    return <div></div>;
  }

  return (
    <TableWrapper gap="1rem">
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup, index) => (
            <tr key={index} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, indexTwo) => (
                <th key={indexTwo} {...column.getHeaderProps()}>
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);

            return (
              <tr key={i} {...row.getRowProps()}>
                {row.cells.map((cell, j) => {
                  return (
                    <td key={j} {...cell.getCellProps()}>
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </TableWrapper>
  );
}

const PurchaseOrderLinesTable = ({purchaseOrder}) => {
  const {goodsItems} = purchaseOrder;

  const columns = React.useMemo(
    () => [
      {
        Header: 'Product',
        accessor: (props) => `${props.item.name} (x${props.item.itemConversionFactor})`,
      },
      {
        Header: 'Amount',
        accessor: 'quantity.value',
      },
      {
        Header: 'Price',
        accessor: 'price.value',
      },
    ],
    []
  );

  const data = React.useMemo(
    () => goodsItems.map((goodsItem) => goodsItem),
    [goodsItems]
  );

  return <Table columns={columns} data={data} purchaseOrder={purchaseOrder} />;
};

export default PurchaseOrderLinesTable;

import {getSalesOrdersDetails} from '../../../apis/ApiCalls';

/**
 * Gets the CSO details based on id
 * @returns {{error : Error, data: {}}}
 */
export async function getDetail() {
  if (!this.id) return {error: 'no id set', data: null};
  let response;

  try {
    response = await getSalesOrdersDetails(this.id);

    if (response.data) {
      this.current = response.data;

      this.current.hasNetSuiteId = Boolean(response.data.netSuiteId);

      if (process.env.REACT_APP_LOG_REDUX) {
        // eslint-disable-next-line
        console.log('salesOrder: ', response.data);
      }

      return {data: response.data, error: null};
    } else {
      return {data: null, error: 'Something went wrong'};
    }
  } catch (error) {
    if (error?.response?.data?.title) {
      return {data: null, error: error.response.data.title};
    }

    return {data: null, error: error.message ?? 'Kapot'};
  }
}

// orderline actions
export const LOAD_ORDERLINES = 'LOAD_ORDERLINES';

// childrendictionary actions
export const LOAD_CHILDRENDICTIONARY = 'LOAD_CHILDRENDICTIONARY';
export const ADD_TO_CHILDRENDICTIONARY = 'ADD_TO_CHILDRENDICTIONARY';

// global cso actions
export const CSO_INIT = 'csoReducer/CSO_INIT';
export const APP_LOADING = 'appReducer/APP_LOADING';
export const ADD_NEW_COMMENT = 'ADD_NEW_COMMENT';
export const SET_DEADLINE = 'SET_DEADLINE';
export const SET_ORDER_STATUS = 'SET_ORDER_STATUS';
export const RESET_CSO = 'RESET_CSO';
export const SET_ORDER_NOT_FOUND = 'SET_ORDER_NOT_FOUND';

// purchaseOrder actions
export const LOAD_PURCHASEORDERS = 'LOAD_PURCHASEORDERS';

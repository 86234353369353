import React, {useEffect, useState} from 'react';
import {Col, Row} from 'react-bootstrap';
import {FaPen, FaTrash} from 'react-icons/fa';

import {
  ProductTooltipBox,
  ProductWrapper,
  ToolTipWrapper,
} from '../../../shared/product/product-row-components';
import {ProductTitle} from '../../../shared/product/ProductTitle';
import {TotalSellPrice} from '../../../shared/product/TotalSellPrice';
import {TotalCostPrice} from '../../../shared/product/TotalCostPrice';
import {TotalPriceDifference} from '../../../shared/product/TotalPriceDifference';
import {BaseModal} from '../../../shared/modals/BaseModal';
import {ProductActionButton} from '../../../shared/buttons/ProductActionButton';
import csoManager from '../../../../utils/csoManager/csoManager';

const AssignableProductRow = ({item, level}) => {
  const [showModal, setShowModal] = useState({isVisible: false, type: ''});

  useEffect(() => {
    return () => setShowModal({isVisible: false, type: ''});
  }, []);

  return (
    <ToolTipWrapper>
      <ProductWrapper
        emptyGuid={item.item.id === '00000000-0000-0000-0000-000000000000'}
        level={level}
        itemType={item.item.itemType}
      >
        <Row>
          <Col style={{paddingLeft: '25px'}}>
            <ProductTitle id={item.item.id} externalIds={item.item.externalIds}>
              <strong>{item.orderedQuantity.value}x</strong>
              <span> {item.item.name}</span>
            </ProductTitle>
          </Col>
          <Col md={3}>
            <TotalSellPrice amount={item.quantity.value} item={item} />

            <TotalCostPrice item={item} />

            <TotalPriceDifference item={item} />
            <div className="mt-2 d-flex justify-content-end">
              <>
                {item.allowedActions.isRemovable && (
                  <ProductActionButton
                    disabled={csoManager?.current?.hasNetSuiteId}
                    onClick={() => setShowModal({isVisible: true, type: 'delete'})}
                  >
                    <FaTrash title="Delete product" size={16} />
                  </ProductActionButton>
                )}
                {item.allowedActions.isMutable && (
                  <ProductActionButton
                    disabled={csoManager?.current?.hasNetSuiteId}
                    onClick={() => setShowModal({isVisible: true, type: 'update'})}
                  >
                    <FaPen title="Change price" size={16} />
                  </ProductActionButton>
                )}
              </>
            </div>
          </Col>
        </Row>
      </ProductWrapper>

      {item.item.id === '00000000-0000-0000-0000-000000000000' && (
        <ProductTooltipBox>
          <p>This product has an empty GUID</p>
        </ProductTooltipBox>
      )}
      <BaseModal
        isVisible={showModal?.isVisible}
        type={showModal.type}
        selectedProduct={item}
        option={false}
        onClose={() => setShowModal({isVisible: false, type: ''})}
      />
    </ToolTipWrapper>
  );
};

export default AssignableProductRow;

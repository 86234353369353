import React from 'react';
import styled from 'styled-components';
import {Button} from 'react-bootstrap';

const ErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 90vh;
`;

const ErrorAndGoBack = ({message, onClick}) => {
  return (
    <ErrorWrapper>
      <h3>{message}</h3>
      <Button onClick={onClick}>Go back</Button>
    </ErrorWrapper>
  );
};

export default ErrorAndGoBack;

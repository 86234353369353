import React from 'react';

import ReservationsTable from './ReservationsTable';

const Reservations = ({reservations}) => {
  const columns = React.useMemo(
    () => [
      {
        Header: 'Reference',
        accessor: 'reference',
      },
      {
        Header: 'Amount',
        accessor: 'count',
      },
    ],
    []
  );

  return (
    <div className="mt-4">
      <h5>Reservations</h5>
      <ReservationsTable columns={columns} data={reservations} />
    </div>
  );
};

export default Reservations;

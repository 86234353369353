import React from 'react';
import {any} from 'prop-types';

export const TotalCostPrice = ({item, sumOfChildren}) => {
  const totalCostPrice = () => {
    if (item?.selectedSupplier) {
      const sum =
        Number(item.quantity?.value) * Number(item.selectedSupplier?.costPrice.value);

      return (
        <p className="my-0 text-right text-muted">
          Purchase price{' '}
          <span>
            {item.price.currency} {sum.toFixed(2)}
          </span>
        </p>
      );
    } else if (sumOfChildren) {
      return (
        <p className="my-0 text-right text-muted">
          Purchase price{' '}
          <span>
            {item.price.currency} {sumOfChildren}
          </span>
        </p>
      );
    } else {
      return <p className="my-0 text-right text-muted">No purchase price</p>;
    }
  };

  return totalCostPrice();
};

TotalCostPrice.propTypes = {
  item: any, // Gadero item object
};
